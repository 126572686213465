export default {
  state: { isPersonalDataCorrect: true, cartItems: [], cartOpen: false },
  getters: {
    isPersonalDataCorrect(state) {
      return state.isPersonalDataCorrect;
    },
    getCartItems(state) {
      return state.cartItems;
    },
    isCartOpen(state) {
      return state.cartOpen;
    },
  },

  mutations: {
    isPersonalDataCorrect(state, isCorrect) {
      state.isPersonalDataCorrect = isCorrect;
    },
    setCartItems(state, data) {
      state.cartItems = data;
    },
    removeItemFromCart(state, data) {
      const indexOfObject = state.cartItems.findIndex((object) => {
        return object.id === data;
      });
      state.cartItems.splice(indexOfObject, 1);
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },
    clearCart(state) {
      state.cartItems = [];
    },
    toggleCartOpen(state) {
      state.cartOpen = !state.cartOpen;
    },
    changeCartOpen(state, data) {
      state.cartOpen = data;
    },
  },
  actions: {
    setPersonalData(context, isCorrect) {
      context.commit("isPersonalDataCorrect", isCorrect);
    },
    setPersonalDataValue(context, value) {
      context.commit("personalData", value);
    },
    setCartItems(context, data) {
      context.commit("setCartItems", data);
    },
    removeItemFromCart(context, data) {
      context.commit("removeItemFromCart", data);
    },
    clearCart(context) {
      context.commit("clearCart");
    },
    changeCartOpen(context, data) {
      context.commit("changeCartOpen", data);
    },
    toggleCartOpen(context) {
      context.commit("toggleCartOpen");
    },
  },
};
