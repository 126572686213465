<template>
  <v-snackbar
    v-model="notification.show"
    :color="notification.color"
    :timeout="getTimeout()"
    :top="true"
    :right="true"
  >
    {{ notification.text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="notification.show = false"
        >Close</v-btn
      >
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    notifications: [],
  }),
  computed: {
    ...mapState(["notification"]),
  },
  methods: {
    getTimeout() {
      if (!this.notification.timeout) {
        this.notification.timeout = this.timeout;
      }
      return this.notification.timeout;
    },
  },
};
</script>

<style lang="scss" scoped></style>
