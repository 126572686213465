<template>
  <v-badge
    class="notifications-menu__badge"
    color="pink"
    :value="getCartItems"
    :content="getCartItems"
  >
    <v-btn max-width="40px" max-height="40px" icon @click="toggleCart">
      <v-icon>mdi-cart</v-icon>
    </v-btn></v-badge
  >
</template>

<script>
export default {
  name: "TopBarCart",

  data() {
    return {};
  },
  computed: {
    getCartItems() {
      return this.$store.getters.getCartItems.length;
    },
  },
  methods: {
    toggleCart() {
      this.$store.dispatch("toggleCartOpen");
    },
  },
};
</script>

<style lang="scss">
.notifications-menu__badge {
  .v-badge__badge {
    margin-left: -13px;
    margin-bottom: -13px;
  }
}
</style>
