const Module = () => import("./Module.vue");
import tokenService from "../auth/services/token.service";
import { i18n } from "@/plugins/i18n";

const routes = [
  {
    path: "/",
    component: Module,
    beforeEnter(to, from, next) {
      if (tokenService.getUser() === "null") next({ name: "Login" });
      else next();
    },
    children: [
      {
        path: "/",
        name: "InfoView",
        meta: {
          title: "Futures - " + i18n.t("info"),
        },
        component: () =>
          import(/* webpackChunkName: "UserInfo" */ "./views/InfoView.vue"),
      },
      {
        path: "/bots",
        name: "BotsView",
        meta: {
          title: "Futures - " + i18n.t("bots"),
        },
        component: () =>
          import(/* webpackChunkName: "UserBots" */ "./views/BotsView.vue"),
      },
      {
        path: "/global-statistics",
        name: "GlobalStatisticsView",
        meta: {
          title: "Futures - " + i18n.t("globalStatistics"),
        },
        component: () =>
          import(
            /* webpackChunkName: "UserBots" */ "./views/GlobalStatisticsView.vue"
          ),
      },
      {
        path: "/trading",
        name: "TradingView",
        meta: {
          title: "Futures - " + i18n.t("tradeHistory"),
        },
        component: () =>
          import(
            /* webpackChunkName: "TradingView" */ "./views/TradingView.vue"
          ),
      },
    ],
  },
];

export default (router) => {
  routes.forEach(function (route) {
    router.addRoute(route);
  });
};
