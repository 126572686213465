export default {
  state: {
    accountDataChanged: {
      balance: 0,
      availableBalance: 0,
      dailyProfit: 0,
      monthlyProfit: 0,
      dailyTrades: 0,
      monthlyTrades: 0,
      payment: 0,
      paymentTotal: 0,
      running: 0,
      paused: 0,
      stopped: 0,
      pnlPercent: 0,
      totalPercent: 0,
      monthlyFee: 0,
      dailyFee: 0,
      lastPaymentDate: "",
      accBalancePercantege: 0,
      accCurrentPercantege: 0,
    },
  },
  getters: {
    getAccountDataChanged(state) {
      return state.accountDataChanged;
    },
    getActiveBots(state) {
      return state.accountDataChanged.activeBots;
    },
  },
  actions: {
    setAccountDataChanged(context, data) {
      context.commit("setAccountDataChanged", data);
    },
  },
  mutations: {
    setAccountDataChanged(state, data) {
      state.accountDataChanged = data;
    },
  },
};
