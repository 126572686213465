import AuthService from "../services/auth.service";
import jwt_decode from "jwt-decode";

const user = JSON.parse(localStorage.getItem("user"));
let roles = [];

try {
  const accessToken = user.accessToken;
  const decoded = jwt_decode(accessToken);
  roles = decoded.Role;
} catch {
  roles = null;
}

const initialState = user
  ? { status: { loggedIn: true }, user, roles }
  : { status: { loggedIn: false }, user: null, roles: null };

export default {
  state: initialState,

  getters: {
    loggedIn(state) {
      return state.status.loggedIn;
    },
    isRole: (state) => (Role) => {
      return state.roles.includes(Role);
    },
    isAdmin(state) {
      return state.roles.includes("Admin");
    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          if (user){
            commit("loginSuccess", user);
          }
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    twoFactorLogin({ commit }, user) {
      return AuthService.twoFactorLogin(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    refreshToken({ commit }, accessToken) {
      commit("refreshToken", accessToken);
    },
    setRoles(context, Roles) {
      context.commit("setRoles", Roles);
    },
  },

  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.roles = null;
    },
    refreshToken(state, accessToken) {
      state.status.loggedIn = true;
      state.user = { ...state.user, accessToken: accessToken };
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
  },
};
