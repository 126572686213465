import axiosInstance from "./api";
import TokenService from "../modules/auth/services/token.service";
import AuthService from "../modules/auth/services/auth.service";
import { store } from "../store";
import router from "@/router";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (store.getters.loggedIn) {
        config.headers.Authorization =
          "Bearer " + TokenService.getLocalAccessToken() || "null";
        config.headers["Accept-Language"] =
          localStorage.getItem("lang") || "sl-SI";
        config.headers["Content-Type"] = "application/json; charset=UTF-8";
      } else {
        config.headers.Authorization = null;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error.config.url == "/api/Auth/RefreshToken") {
        AuthService.logout();
      }

      // Return any error which is not due to authentication
      if (error.response.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      //Get new tokens
      return getNewTokens()
        .then(() => {
          //Retry the request
          const config = error.config;
          return new Promise((resolve, reject) => {
            axiosInstance
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error) => {
          Promise.reject(error);
        });
    }
  );
};

export function getNewTokens() {
  if (router.currentRoute.name == "Login" || router.currentRoute.name == "ChangePassword") return;
  var oldToken = TokenService.getLocalRefreshToken();
  //Exchange old tokens for new one
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("/api/Auth/RefreshToken", {
        accessToken: TokenService.getLocalAccessToken(),
        refreshToken: TokenService.getLocalRefreshToken(),
      })
      .then((response) => {
        //Store newly generated tokens
        console.log(
          "Refreshing old token: " +
            oldToken +
            ", for new one: " +
            response.data.value.refreshToken
        );

        TokenService.setUser(response.data.value);

        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export default setup;
