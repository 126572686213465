import axios from "axios";

let instance;
// const instance = axios.create({
//   baseURL: process.env.VUE_APP_BACKEND_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

if (process.env.VUE_APP_MODE == "development") {
  //This is for development
  instance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
} else {
  //This is for production where nginx does the routing
  instance = axios.create({
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default instance;
