<template>
  <v-menu transition="slide-y-transition" bottom offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn max-width="40px" max-height="40px" icon v-bind="attrs" v-on="on">
        <v-icon> mdi-share-variant </v-icon>
      </v-btn>
    </template>
    <v-list color="#324152">
      <v-list-item
        v-for="(item, i) in social"
        :key="i"
        :href="item.to"
        link
        target="_blank"
      >
        <v-list-item-title class="main-menu__title">
          <span class="main-menu__icon"
            ><v-icon color="white" dark>
              {{ item.icon }}
            </v-icon></span
          >{{ item.title }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "TopBarSocial",
  data() {
    return {
      social: [
        {
          title: "Youtube",
          to: "https://youtube.com/channel/UCM6SJXmrW3rlY8r_AAXfTSw",
          icon: "mdi-youtube",
        },
        {
          title: "Linkedin",
          to: "https://www.linkedin.com/company/grumson-d-o-o",
          icon: "mdi-linkedin",
        },
        {
          title: "Facebook",
          to: "https://www.facebook.com/GBOT-108901181578757/",
          icon: "mdi-facebook",
        },
        {
          title: "Instagram",
          to: "https://instagram.com/gbotcryptotrading?utm_medium=copy_link",
          icon: "mdi-instagram",
        },
      ],
    };
  },
};
</script>
