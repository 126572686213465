<template>
  <v-navigation-drawer
    v-model="openCart"
    fixed
    temporary
    right
    hide-overlay
    width="400"
    color="#324152"
  >
    <div class="d-flex flex-column">
      <v-row>
        <v-icon class="ma-6" v-if="showGoBack" @click="back"
          >mdi-arrow-left</v-icon
        >
        <v-icon class="ma-6 ml-auto" @click="$store.dispatch('toggleCartOpen')"
          >mdi-close</v-icon
        ></v-row
      >

      <v-tabs-items v-model="tab">
        <v-tab-item :key="1" value="cart">
          <p class="ma-4">
            {{ $t("cart") }}
          </p>

          <v-divider></v-divider>

          <v-list dense v-if="cartItems.length">
            <v-list-item v-for="item in cartItems" :key="item.id" link>
              <v-list-item-content>
                <v-list-item-title v-model="item.type"></v-list-item-title>

                <v-list-item-subtitle>{{ item.comment }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text
                  >{{ getLocalNumber(item.amount) }} €</v-list-item-action-text
                >
                <v-icon
                  small
                  @click="$store.dispatch('removeItemFromCart', item.id)"
                >
                  mdi-delete
                </v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-divider></v-divider>
            <p class="ma-4">
              {{ $t("tax") }}:
              <span style="float: right"
                >{{ getLocalNumber(getTotalPrice - getNetPrice) }} €</span
              >
            </p>
            <p class="ma-4">
              {{ $t("summary") }}:
              <span style="float: right"
                >{{ getLocalNumber(getTotalPrice) }} €</span
              >
            </p>
          </v-list>
          <p class="text-center my-4" v-else>{{ $t("emptyCart") }}</p>

          <div class="d-flex flex-column">
            <v-btn
              class="ma-2"
              color="success"
              v-if="cartItems.length"
              @click="tab = 'checkout'"
            >
              {{ $t("buttonNext") }}
            </v-btn>
          </div>
        </v-tab-item>
        <v-tab-item :key="2" value="checkout">
          <p class="ma-4">
            {{ $t("payWith") }}
          </p>

          <v-divider></v-divider>
          <div class="d-flex flex-column">
            <v-radio-group v-model="paymentType" class="ma-4">
              <v-radio
                v-for="item in paymentTypes"
                :key="item[1]"
                :label="item[0]"
                :value="item[1]"
              ></v-radio>
            </v-radio-group>
            <p class="ma-4 mt-0">
              {{ $t("summary") }}:
              <span style="float: right"
                >{{ getLocalNumber(getTotalPrice) }} €</span
              >
            </p>
            <v-divider></v-divider>
            <v-btn
              class="ma-2"
              color="success"
              v-if="cartItems.length"
              @click="next"
            >
              {{ $t("buttonNext") }}
            </v-btn>
          </div>
        </v-tab-item>

        <v-tab-item :key="3" value="proForma">
          <v-container class="d-flex px-3 flex-column">
            <p class="my-4">{{ $t("proForma") }}</p>
            <p class="my-4">
              {{ $t("value") }}
              <span style="float: right"
                >{{ getLocalNumber(getNetPrice) }} €</span
              >
            </p>
            <v-divider class="ma-0" />
            <p class="my-4">
              {{ $t("VAT") }}:
              <span style="float: right"
                >{{ getLocalNumber(getTotalPrice - getNetPrice) }} €</span
              >
            </p>
            <v-divider class="ma-0" />
            <p class="my-4">
              {{ $t("summary") }}:
              <span style="float: right"
                >{{ getLocalNumber(getTotalPrice) }} €</span
              >
            </p>
            <v-btn
              class="my-2"
              color="success"
              @click="requestProformaInvoice()"
            >
              {{ $t("payWithproForma") }}
            </v-btn>
          </v-container>
        </v-tab-item>

        <v-tab-item :key="4" value="paypal">
          <PayPal
            ref="payPal"
            :authToken="authToken"
            :value="getNetPrice"
            :total="getTotalPrice"
            @back="back"
            @payPalError="payPalError"
            @payPalButtonReady="payPalButtonReady"
            @paypalPaymentSubmit="submitTransaction"
            @deviceDataPayload="deviceDataPayload"
          ></PayPal>
        </v-tab-item>

        <v-tab-item :key="5" value="card">
          <CardFields
            ref="cardPayment"
            :authToken="authToken"
            :value="getNetPrice"
            :total="getTotalPrice"
            @back="back"
            @cardPaymentError="cardPaymentError"
            @cardPaymentReady="cardPaymentReady"
            @cardPaymentSubmit="submitTransaction"
            @deviceDataPayload="deviceDataPayload"
          ></CardFields>
        </v-tab-item>
      </v-tabs-items>
      <v-dialog
        content-class="download-progress"
        persistent
        style="overflow: none"
        :value="loading"
      >
        <v-progress-circular indeterminate opacity="50" size="64">
          <v-icon size="50">$vuetify.icons.gbot</v-icon>
        </v-progress-circular>
      </v-dialog>
      <v-dialog v-model="paymentStatusDialog" max-width="350">
        <v-card>
          <v-card-text class="subscription-popup">
            <svg
              v-if="paymentStatus"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
            >
              <path
                id="checkbox-marked-circle-outline"
                d="M74,42A32.025,32.025,0,1,1,50.8,11.24l6.28-6.28A39.288,39.288,0,0,0,42,2,40,40,0,1,0,82,42M25.64,34.32,20,40,38,58,78,18l-5.64-5.68L38,46.68Z"
                transform="translate(-2 -2)"
                fill="#22a652"
              />
            </svg>

            <svg
              v-if="!paymentStatus"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 80 80"
            >
              <path
                id="alert-circle-outline"
                d="M38,54h8v8H38V54m0-32h8V46H38V22M42,2A40,40,0,1,0,82,42,40.04,40.04,0,0,0,42,2m0,72A32,32,0,1,1,74,42,32,32,0,0,1,42,74Z"
                transform="translate(-2 -2)"
                fill="#e53c2f"
              />
            </svg>

            <h2 v-if="paymentStatus">{{ $t("titleSucces") }}</h2>
            <h2 v-if="!paymentStatus">{{ $t("titleFail") }}</h2>
            <p v-if="paymentStatus">
              {{ $t("subtitleSucces") }}
            </p>
            <p v-if="!paymentStatus">
              {{ $t("subtitleFail") }}
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-navigation-drawer>
</template>

<script>
import api from "@/services/api";
import CardFields from "./CardFields.vue";
import PayPal from "./PayPal.vue";

export default {
  components: {
    CardFields,
    PayPal,
  },
  data() {
    return {
      tab: "cart",
      openCart: false,
      paymentType: 0,
      paymentTypes: [
        [this.$t("proForma"), 0],
        [this.$t("paypal"), 2],
        [this.$t("card"), 3],
      ],
      loading: false,
      deviceData: "",
      authToken: "",
      payload: "",
      cardHolder: "",
      paymentStatusDialog: false,
      paymentStatus: false,
    };
  },
  watch: {
    openCart() {
      this.$store.dispatch("changeCartOpen", this.openCart);
      if (this.cartItems.length == 0) this.tab = "cart";
    },
    isCartOpen(v) {
      this.openCart = v;
    },
  },

  computed: {
    isCartOpen() {
      return this.$store.getters.isCartOpen;
    },
    cartItems() {
      return this.$store.getters.getCartItems;
    },
    getTotalPrice() {
      const sum = this.cartItems.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.amountWithTax,
        0
      );

      return sum;
    },
    getNetPrice() {
      const sum = this.cartItems.reduce(
        (previousValue, currentValue) => previousValue + currentValue.amount,
        0
      );

      return sum;
    },
    showGoBack() {
      if (this.tab === "cart") {
        return false;
      }
      return true;
    },
  },

  methods: {
    getDate(v) {
      return this.$d(new Date(v), "small");
    },
    getLocalNumber(v) {
      if (localStorage.getItem("lang") === "en-US") {
        return v.toFixed(2);
      } else {
        return parseFloat(v).toFixed(2).toString().replace(".", ",");
      }
    },

    back() {
      if (this.tab === "checkout") {
        this.tab = "cart";
      } else if (
        this.tab === "proForma" ||
        this.tab === "paypal" ||
        this.tab === "card"
      ) {
        this.tab = "checkout";
        this.authToken = "";
        this.deviceData = "";
        this.payload = "";
        this.cardHolder = "";
        if (this.paymentType == 2) {
          this.$refs.payPal.back();
        } else if (this.paymentType == 3) {
          this.$refs.cardPayment.back();
        }
      }
    },
    async next() {
      if (this.paymentType == 0) {
        //Pro-forma invoice
        this.tab = "proForma";
      } else if (this.paymentType == 2) {
        //PayPal payment
        this.deviceData = "";
        await this.setPaypal();
        this.tab = "paypal";
        console.log(this.$refs);
        if (this.$refs.payPal != undefined) {
          this.$refs.payPal.createBrainTree();
        }
      } else if (this.paymentType == 3) {
        //Card payment
        this.deviceData = "";
        await this.setCardPayment();
        this.tab = "card";
        if (this.$refs.cardPayment != undefined) {
          this.$refs.cardPayment.createBrainTree();
        }
      }
    },
    async requestProformaInvoice() {
      try {
        this.loading = true;
        var value = await this.cartPayment();
        var data = await JSON.parse(value);
        this.downloadPDF(data.Data, data.FileName, data.ContentType);
        this.loading = false;
        this.$store.dispatch("toggleCartOpen");
        localStorage.removeItem("cart");
        this.$store.dispatch("clearCart");
        this.$root.$refs.userPayments_component.getUserPayments();
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data,
        });
      }
    },
    async cartPayment() {
      const res = await api.post("api/Checkout/CartPayment", {
        Items: this.cartItems,
        PaymentType: this.paymentType,
        Cardholder: this.cardHolder,
        Nonce: this.payload,
        DeviceData: JSON.stringify(this.deviceData),
      });

      return res.data.value;
    },
    downloadPDF(data, fileName, contentType) {
      const linkSource = `data:${contentType};base64,${data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    async getClientToken() {
      try {
        this.loading = true;
        const res = await api.post("/api/Checkout/GetClientToken", {
          Value: this.paymentType.toString(),
        });
        return res.data.value;
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data,
        });
      }
    },
    async setPaypal() {
      this.authToken = await this.getClientToken();
    },
    async setCardPayment() {
      this.authToken = await this.getClientToken();
    },
    cardPaymentError(error) {
      this.loading = false;
      console.log("cardPaymentError > " + error);
      this.$store.dispatch("raiseNotification", {
        show: true,
        color: "error",
        text: "Card payment error: " + error,
      });
    },
    cardPaymentReady() {
      this.loading = false;
      this.$store.dispatch("raiseNotification", {
        show: true,
        color: "success",
        text: "Card payment ready!!!",
      });
    },
    async submitTransaction(payload, cardHolder) {
      try {
        console.log("payload > " + payload + " cardHolder > " + cardHolder);
        this.payload = payload;
        this.cardHolder = cardHolder;
        console.log("deviceData: " + this.deviceData);
        console.log("cardHolder: " + this.cardHolder);

        this.loading = true;
        var value = await this.cartPayment();

        if (value == "True") {
          this.paymentStatus = true;
          this.paymentStatusDialog = true;
          this.$emit("close", true);

          this.$store.dispatch("toggleCartOpen");
          localStorage.removeItem("cart");
          this.$store.dispatch("clearCart");
          this.$root.$refs.userPayments_component.getUserPayments();
        } else {
          this.paymentStatus = false;
          this.paymentStatusDialog = true;
          this.$emit("close", false);
        }

        this.back();
        this.loading = false;
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err,
        });
        this.loading = false;
      }
    },
    payPalError(error) {
      this.loading = false;
      console.log("payPalError > " + error);
      this.$store.dispatch("raiseNotification", {
        show: true,
        color: "error",
        text: "PayPal payment error: " + error,
      });
    },
    payPalButtonReady() {
      this.loading = false;
      this.$store.dispatch("raiseNotification", {
        show: true,
        color: "success",
        text: "PayPal ready!!!",
      });
    },
    deviceDataPayload(deviceData) {
      console.log("deviceDataPayload > " + deviceData);
      this.deviceData = deviceData;
    },
  },
};
</script>

<style></style>
