const Module = () => import("./Module.vue");
import tokenService from "@/modules/auth/services/token.service";
import { i18n } from "@/plugins/i18n";

const routes = [
  {
    path: "/",
    component: Module,
    async beforeEnter(to, from, next) {
      if (tokenService.getUser() === "null") next({ name: "Login" });
      else next();
    },
    children: [
      {
        path: "/release-notes",
        name: "ReleaseNotes",
        meta: {
          title: i18n.t("releaseNote"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/ReleaseNotesView.vue"
          ),
      },
      {
        path: "/documents",
        name: "Documents",
        meta: {
          title: i18n.t("documents"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/DocumentsView.vue"
          ),
      },
      {
        path: "/settings-general",
        name: "Generalsettings",
        meta: {
          title: i18n.t("general"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/GeneralSettingsView.vue"
          ),
      },
      {
        path: "/settings-personal",
        name: "Personalsettings",
        meta: {
          title: i18n.t("personal"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/PersonalSettingsView.vue"
          ),
      },
      {
        path: "/faq",
        name: "Faq",
        meta: {
          title: i18n.t("faq0"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/FaqView.vue"
          ),
      },
      {
        path: "/user-referral",
        name: "UserReferral",
        meta: {
          title: i18n.t("referral"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/UserReferralView.vue"
          ),
      },
      {
        path: "/user-payments",
        name: "UserPayments",
        meta: {
          title: i18n.t("receipts"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/UserPaymentsView.vue"
          ),
      },
    ],
  },
];

export default (router) => {
  routes.forEach(function (route) {
    router.addRoute(route);
  });
};
