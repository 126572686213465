export default {
  state: {
    spotAccountDataChanged: {
      running: 0,
      paused: 0,
      stopped: 0,
      dailyTrades: 0,
      monthlyTrades: 0,
      assetTotalBalanceUSDT: 0,
    },
  },
  getters: {
    getSpotAccountDataChanged(state) {
      return state.spotAccountDataChanged;
    },
  },
  actions: {
    setSpotAccountDataChanged(context, data) {
      context.commit("setSpotAccountDataChanged", data);
    },
  },
  mutations: {
    setSpotAccountDataChanged(state, data) {
      state.spotAccountDataChanged = data;
    },
  },
};
