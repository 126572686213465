<template>
  <div>
    <v-tabs-items v-model="tab">
      <v-tab-item :key="1" value="first">
        <v-card width="800px">
          <v-container>
            <p class="ma-4">
              {{ $t("payWith") }}
            </p>
            <v-radio-group v-model="paymentType" class="ma-4">
              <v-radio
                v-for="item in paymentTypes"
                :key="item[1]"
                :label="item[0]"
                :value="item[1]"
              ></v-radio>
            </v-radio-group>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color=" darken-1" text @click="$emit('close')">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn color="success darken-1" text @click="next">
              {{ $t("buttonNext") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2" value="proForma">
        <v-card width="800px">
          <v-container>
            <p class="ma-4">{{ $t("proForma") }}</p>
            <p class="ma-4">
              {{ subscription.name }}
              <span style="float: right"
                >{{ getLocalNumber(subscription.total) }} €</span
              >
            </p>
            <hr />
            <p class="ma-4 my-2">
              {{ $t("VAT") }}:
              <span style="float: right"
                >{{
                  getLocalNumber(subscription.totalGross - subscription.total)
                }}
                €</span
              >
            </p>
            <hr />
            <p class="ma-4">
              {{ $t("summary") }}:
              <span style="float: right"
                >{{ getLocalNumber(subscription.totalGross) }} €</span
              >
            </p>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color=" darken-1" text @click="tab = 'first'">
              {{ $t("back") }}
            </v-btn>
            <v-btn color="success darken-1" text @click="requestLicence()">
              {{ $t("payWithproForma") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      content-class="download-progress"
      persistent
      style="overflow: none"
      :value="downloading"
    >
      <v-progress-circular indeterminate opacity="50" size="64">
        <v-icon size="50">$vuetify.icons.gbot</v-icon>
      </v-progress-circular>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      tab: "first",
      paymentType: 0,
      paymentTypes: [
        [this.$t("proForma"), 0],
        /*    ["Stripe", 1],
          ["PayPal", 2], */
      ],
      selectedPayment: null,
      downloading: false,
    };
  },
  props: {
    subscription: Object,
    isOpen: Boolean,
  },
  watch: {
    isOpen() {
      this.tab = "first";
    },
  },
  methods: {
    getLocalNumber(v) {
      if (localStorage.getItem("lang") === "en-US") {
        return v.toFixed(2);
      } else {
        return parseFloat(v).toFixed(2).toString().replace(".", ",");
      }
    },
    next() {
      if (this.paymentType == 0) this.tab = "proForma";
      /* if (this.paymentType == 1) this.requestLicence(true);
        if (this.paymentType == 2) this.requestLicence(true); */
    },
    async requestLicence() {
      try {
        this.downloading = true;
        const res = await api.post("/api/Checkout/SubscriptionPayment", {
          SubscriptionID: this.subscription.subscriptionID,
          BillingID: this.subscription.billingID,
        });
        var data = await JSON.parse(res.data.value);
        this.downloadPDF(data.Data, data.FileName, data.ContentType);
        this.downloading = false;
        this.$emit("close", true);
        this.tab = "first";
      } catch (err) {
        this.downloading = false;
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data,
          timeout: 8000,
        });
      }
    },
    downloadPDF(data, fileName, contentType) {
      const linkSource = `data:${contentType};base64,${data}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #212b39;
  min-width: 40vw;
}
h2 {
  padding-bottom: 2rem;
}
</style>
