<template>
  <v-container class="d-flex px-3 flex-column">
    <div v-if="showSummary">
      <p class="my-4">{{ $t("card") }}</p>
      <p class="my-4">
        {{ $t("value") }}
        <span style="float: right">{{ getLocalNumber(value) }} €</span>
      </p>
      <v-divider class="ma-0" />
      <p class="my-4 my-2">
        {{ $t("VAT") }}:
        <span style="float: right">{{ getLocalNumber(total - value) }} €</span>
      </p>
      <v-divider class="ma-0" />
      <p class="my-4">
        {{ $t("summary") }}:
        <span style="float: right">{{ getLocalNumber(total) }} €</span>
      </p>
      <v-divider class="ma-0 mb-8" />
      <div class="alert alert-danger" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>

    <form class="stripe-form">
      <div class="form-group">
        <label>{{ this.$t("cardholderName") }}</label>
        <input
          type="text"
          id="cardholderName"
          name="cardholderName"
          class="form-control"
          :placeholder="this.$t('cardholderPlaceholder')"
        />
      </div>
      <div class="form-group">
        <label>{{ this.$t("creditCardNumber") }}</label>
        <div
          id="creditCardNumber"
          name="creditCardNumber"
          class="form-control"
          :placeholder="this.$t('enterCreditCard')"
        ></div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <label>{{ this.$t("expireDate") }}</label>
            <div
              id="expireDate"
              name="expireDate"
              class="form-control"
              placeholder="00 / 0000"
            ></div>
          </div>
          <div class="col-6">
            <label>{{ this.$t("cvv") }}</label>
            <div
              id="cvv"
              name="cvv"
              class="form-control"
              :placeholder="this.$t('cvv')"
            ></div>
          </div>
        </div>
      </div>
    </form>
    <v-btn
      color="darken-1"
      text
      v-if="showBackButton"
      @click="backButton"
      class="my-2"
    >
      {{ $t("back") }}
    </v-btn>
    <v-btn color="success" @click="tokenizeHostedFields"
      >{{ showSummary ? $t("payWithCreditCard") : $t("confirmCard") }}
    </v-btn>
  </v-container>
</template>

<script>
import braintree from "braintree-web";

export default {
  name: "CardFields",
  components: {},
  props: {
    authToken: {
      value: String,
    },
    value: Number,
    total: Number,
    showBackButton: Boolean,
    showSummary: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
      errorMessage: "",
      clientInstance: "",
      hostedFieldsInstance: "",
    };
  },
  created() {
    this.createBrainTree();
  },
  methods: {
    getLocalNumber(v) {
      if (localStorage.getItem("lang") === "en-US") {
        return v.toFixed(2);
      } else {
        return parseFloat(v).toFixed(2).toString().replace(".", ",");
      }
    },
    backButton() {
      this.$emit("back");
      this.back();
    },
    back() {
      this.teardownHostedFields();
      this.errorMessage = "";
      this.clientInstance = "";
      this.hostedFieldsInstance = "";
    },
    createBrainTree() {
      braintree.client.create(
        {
          authorization: this.authToken,
        },
        (clientErr, clientInstance) => {
          if (clientErr) {
            this.errorMessage =
              "There was an error setting up the client instance. Message: " +
              clientErr.message;
            this.$emit("cardPaymentError", this.errorMessage);
            return;
          } else {
            this.clientInstance = clientInstance;
            this.createHostedFields();
            this.dataCollectorCreate();
          }
        }
      );
    },
    createHostedFields() {
      braintree.hostedFields.create(
        {
          client: this.clientInstance,
          styles: {
            input: { color: "white" },
            "input.invalid": {
              color: "red",
            },
            "input.valid": {
              color: "white",
            },
          },
          fields: {
            number: {
              selector: "#creditCardNumber",
            },
            cvv: {
              selector: "#cvv",
            },
            expirationDate: {
              selector: "#expireDate",
            },
          },
        },
        (hostedFieldsErr, hostedFieldsInstance) => {
          if (hostedFieldsErr) {
            // Handle error in Hosted Fields creation
            this.errorMessage =
              "There was an error setting up the hosted fields! Message: " +
              hostedFieldsErr.message;
            this.$emit("cardPaymentError", this.errorMessage);
            return;
          } else {
            this.$emit("cardPaymentReady");
            this.hostedFieldsInstance = hostedFieldsInstance;
          }
        }
      );
    },
    tokenizeHostedFields() {
      const additionalFields = {
        cardholderName: document.querySelector("#cardholderName").value,
      };

      this.hostedFieldsInstance.tokenize(
        additionalFields,
        (tokenizeErr, payload) => {
          if (tokenizeErr) {
            this.errorMessage =
              "There was an error tokenizing! Message: " + tokenizeErr.message;
            this.$emit("cardPaymentError", this.errorMessage);
            return;
          }
          this.$emit(
            "cardPaymentSubmit",
            payload.nonce,
            additionalFields.cardholderName
          );
          this.teardownHostedFields();
        }
      );
    },
    teardownHostedFields() {
      if (this.hostedFieldsInstance === "") {
        return;
      }
      this.hostedFieldsInstance.teardown((teardownErr) => {
        if (teardownErr) {
          this.errorMessage =
            "There was an error tearing it down! Message: " +
            teardownErr.message;
          this.$emit("cardPaymentError", this.errorMessage);
          return;
        } else {
          this.hostedFieldsInstance = "";
          return;
        }
      });
    },
    dataCollectorCreate() {
      braintree.dataCollector.create(
        {
          client: this.clientInstance,
          kount: true,
        },
        (dataCollectorErr, dataCollectorInstance) => {
          if (dataCollectorErr) {
            this.errorMessage =
              "There was an error setting up the data collector! Message: " +
              dataCollectorErr.message;
            this.$emit("cardPaymentError", this.dataCollectorErr);
            return;
          }
          //TODO: Anže what is this? -> device.data.payload
          this.$emit("deviceDataPayload", dataCollectorInstance.deviceData);
        }
      );
    },
  },
};
</script>

<style lang="scss">
#creditCardNumber,
#cvv,
#expireDate {
  background-color: #212b39;
  border-radius: 8px;
  padding: 4px;
  height: 40px;
  color: white;
  margin: 6px 0 12px 0;
}
.stripe-form {
  input {
    width: 100%;
    background-color: #212b39;
    border-radius: 8px;
    padding: 4px;
    height: 40px;
    color: white !important;
    margin: 6px 0 12px 0;
    &:focus-visible {
      outline: none;
    }
  }
}
</style>
