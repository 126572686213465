<template>
  <div>
    <v-card class="overflow-hidden">
      <v-card-title class="text-h5"> </v-card-title>
      <span class="text-h5 ml-10" style="color:#ffd86e">
        {{ $t("downloadBot1") }}
      </span>
      <v-container>
        <v-col class="pa-8">
          <p>
            {{ $t("downloadBot2_1") }}<strong style="color:#ffd86e;font-weight:500;">{{ $t("downloadBot2_2") }}</strong>{{ $t("downloadBot2_3") }}<strong style="color:#ffd86e;font-weight:500;">{{ $t("downloadBot2_4") }}</strong>{{ $t("downloadBot2_5") }}<br />
            <br/>
            <ul>
              <li><strong style="color:#ffd86e;font-weight:500;">{{ $t("downloadBotList1_1") }}</strong>{{ $t("downloadBotList1_2") }}</li>
              <li><strong style="color:#ffd86e;font-weight:500;">{{ $t("downloadBotList2_1") }}</strong>{{ $t("downloadBotList2_2") }}</li>
              <li><strong style="color:#ffd86e;font-weight:500;">{{ $t("downloadBotList3_1") }}</strong>{{ $t("downloadBotList3_2") }}</li>
            </ul>
            <br/>
            <strong style="color:#ffd86e;font-weight:500;">{{ $t("downloadBot3_1") }}</strong>{{ $t("downloadBot3_2") }}
          </p>
        </v-col>
      </v-container>
      <v-card-actions style="padding-bottom:30px;margin-top:-20px">
        <v-spacer></v-spacer>
        <v-btn
          color="#ffd86e"
          text
          background-color="#000000"
          @click="downloadBot"
        >
          <span>★</span><strong>{{ $t("downloadApp") }}</strong><span>★</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog
        content-class="download-progress"
        persistent
        style="overflow: none"
        :value="downloading"
      >
        <v-progress-circular indeterminate opacity="50" size="64">
          <v-icon size="50">$vuetify.icons.gbot</v-icon>
        </v-progress-circular>
    </v-dialog>
  </div>
</template>

<style></style>
<script>
import api from "@/services/api";
export default {
  props: {
    
  },
  data(){
    return {
      downloading: false,
    }
  },
  computed: {
  },
  methods: {
    async downloadBot() {
      this.downloading = true;
      
      window.Moengage.track_event("download_bot");
      this.$gtm.trackEvent({event: "interaction", category: "Download bot", label: "Download bot started"});

      api
        .get("/api/TradingBot/DownloadBotV4", { responseType: "blob" })
        .then((response) => {
          this.downloading = false;
          const link = document.createElement("a");
          const downloadedFile = new Blob([response.data], {
            type: response.data.type,
          });
          link.href = URL.createObjectURL(downloadedFile);
          link.download = response.headers["filename"];
          link.click();
          URL.revokeObjectURL(link.href);
          this.$store.dispatch("setShowTutorial", false);
          this.$gtm.trackEvent({event: "interaction", category: "Download bot", label: "Download bot finished"});
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response != null &&
            error.response != undefined &&
            error.response.status == 400 &&
            error.response.data != null &&
            error.response.data != undefined &&
            error.response.data.error != null &&
            error.response.data.error != undefined
          ) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: error.response.data.error,
            });
          }
          this.downloading = false;
        });
    },
  }
};
</script>