<template>
  <v-menu
    class="lang-menu"
    transition="slide-y-transition"
    bottom
    offset-y
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-capitalize font-weight-medium lang-menu__title"
        text
        v-bind="attrs"
        v-on="on"
      >
        <span><img :src="getFlag" alt="flag" /></span>
      </v-btn>
    </template>

    <v-list dense class="lang-menu__dropdown" color="#324152">
      <v-list-item>
        <v-list-item-title class="lang-menu__title">
          <v-btn
            class="text-capitalize font-weight-medium lang-menu__title"
            text
            @click="setLang('si')"
          >
            <span><img src="@/assets/si.png" alt="flag" /></span>
            {{ $t("si") }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>

      <v-list-item>
        <v-list-item-title class="lang-menu__title">
          <v-btn
            class="text-capitalize font-weight-medium lang-menu__title"
            text
            @click="setLang('en')"
          >
            <span><img src="@/assets/en.png" alt="flag" /></span>
            {{ $t("en") }}
          </v-btn>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "AppBarLang",
  computed: {
    getFlag() {
      const images = require.context("../../assets/", false, /\.png$/);
      if (localStorage.getItem("lang") === "en-US") {
        return images("./en.png");
      } else return images("./si.png");
    },
  },
  methods: {
    setLang(lang) {
      if (lang === "en") {
        localStorage.setItem("lang", "en-US");
      } else {
        localStorage.setItem("lang", "sl-SI");
      }
      location.reload();
    },
  },
};
</script>

<style lang="scss">
.lang-menu__dropdown {
  padding: 0 !important;
  div {
    padding: 0 !important;
  }
}
.lang-menu__title {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  font-weight: 400;
  span {
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
}
</style>
