<template>
  <v-card class="overflow-hidden">
    <v-card-title class="text-h5"> {{ $t("reportBugs") }} </v-card-title>
    <v-form ref="form">
      <v-textarea
        class="ml-6 mr-6"
        maxlength="250"
        v-model="description"
        :label="$t('bugDetails')"
        :rules="[rules.required]"
      ></v-textarea>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions>
      <AppButton
        :name="$t('send')"
        color="white"
        type="text"
        position="right"
        @click="sendBug"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
import api from "@/services/api";
import AppButton from "./AppButton.vue";
export default {
  name: "userReportBugs",
  components: { AppButton },
  data() {
    return {
      description: "",
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },
  methods: {
    async sendBug() {
      if (!this.$refs.form.validate()) return;
      if (this.$cookies.get("sendBug") === "true") {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: this.$t("sendBugEroor"),
        });
        return;
      }
      try {
        const res = await api.post("/api/User/ReportBug", {
          Value: JSON.stringify({
            Description: this.description,
          }),
        });
        if (res.data.error != null) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "error",
            text: res.data.error,
          });
        } else {
          this.$cookies.set("sendBug", "true", 60 * 60 * 24);
          this.description = "";
        }
      } catch (err) {
        if (err.response.status == 400 && err.response.data.error != null) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "error",
            text: err.response.data.error,
          });
        }
      }
      this.$emit("clicked");
    },
  },
};
</script>
