<template>
  <div>
    <div v-if="$store.getters.isAdmin" class="online-data">
      <p class="online-data__title">{{ $t("online1") }}</p>
      <div>
        <v-card
          ><p>{{ $t("users") }}: {{ ConnectedUsersChanged }}</p></v-card
        >
        <v-card>
          <p>{{ $t("bots") }}: {{ ConnectedBotsChanged }}</p></v-card
        >
      </div>
    </div>
    <p>
      Copyright &copy; {{ year }}, GBot. <br />
      {{ $t("rights") }}
    </p>
  </div>
</template>

<script>
import callHub from "@/services/callHub";
export default {
  name: "AppFooter",
  data() {
    return {
      year: new Date().getFullYear(),
      ConnectedUsersChanged: "0",
      ConnectedBotsChanged: "0",
    };
  },
  mounted() {
    if (this.$store.getters.loggedIn) {
      this.getOnlineData();
    }
  },

  methods: {
    getOnlineData() {
      var self = this;
      callHub.client.on("ConnectedUsersChanged", function (call) {
        self.ConnectedUsersChanged = call;
      });
      callHub.client.on("ConnectedBotsChanged", function (call) {
        self.ConnectedBotsChanged = call;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.online-data {
  flex: 100;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: 1rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  p {
    padding: 0.5rem 1.5rem;
    margin: 0;
  }
  .online-data__title {
    text-align: center;
    margin-bottom: 0.2rem;
  }
}
p {
  width: 100%;
  font-size: 0.7rem;
  line-height: 12px;
  text-align: center;
  color: white;
}
</style>
