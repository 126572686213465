const Module = () => import("./Module.vue");
import { store } from "@/store";
import tokenService from "@/modules/auth/services/token.service";
import { i18n } from "@/plugins/i18n";

const routes = [
  {
    path: "/admin",
    component: Module,
    async beforeEnter(to, from, next) {
      if (tokenService.getUser() === "null") next({ name: "Login" });
      if (!store.getters.isAdmin) next({ name: "PageNotFound" });
      else next();
    },
    children: [
      {
        path: "activation-codes",
        name: "ActivationCodesView",
        meta: {
          title: "Admin - " + i18n.t("activationCode"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/ActivationCodesView.vue"
          ),
      },
      {
        path: "add-faqs",
        name: "AddFaqsView",
        meta: {
          title: "Admin - " + i18n.t("faq0"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ActivationCodes" */ "./views/AddFaqsView.vue"
          ),
      },
      {
        path: "banners",
        name: "BannersView",
        meta: {
          title: "Admin - " + i18n.t("banners"),
        },
        component: () =>
          import(
            /* webpackChunkName: "BannersView" */ "./views/BannersView.vue"
          ),
      },
      {
        path: "bot-versions",
        name: "BotVersionsView",
        meta: {
          title: "Admin - " + i18n.t("botVersion"),
        },
        component: () =>
          import(
            /* webpackChunkName: "BotVersionsView" */ "./views/BotVersionsView.vue"
          ),
      },
      {
        path: "global-settings",
        name: "GlobalSettingsView",
        meta: {
          title: "Admin - " + i18n.t("settings"),
        },
        component: () =>
          import(
            /* webpackChunkName: "GlobalSettingsView" */ "./views/GlobalSettingsView.vue"
          ),
      },
      {
        path: "licenses",
        name: "LicensesView",
        meta: {
          title: "Admin - " + i18n.t("licenses"),
        },
        component: () =>
          import(
            /* webpackChunkName: "LicensesView" */ "./views/LicensesView.vue"
          ),
      },
      {
        path: "manual-trades",
        name: "ManualTradesView",
        meta: {
          title: "Admin - " + i18n.t("manualTrade"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ManualTradesView" */ "./views/ManualTradesView.vue"
          ),
      },
      {
        path: "payments",
        name: "PaymentsView",
        meta: {
          title: "Admin - " + i18n.t("receipts"),
        },
        component: () =>
          import(
            /* webpackChunkName: "PaymentsView" */ "./views/PaymentsView.vue"
          ),
      },
      {
        path: "referrals",
        name: "ReferralsView",
        meta: {
          title: "Admin - " + i18n.t("referral"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ReferralsView" */ "./views/ReferralsView.vue"
          ),
      },
      {
        path: "reported-bugs",
        name: "ReportedBugsView",
        meta: {
          title: "Admin - " + i18n.t("reportedBugs"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ReportedBugsView" */ "./views/ReportedBugsView.vue"
          ),
      },
      {
        path: "statistics",
        name: "StatisticsView",
        meta: {
          title: "Admin - " + i18n.t("statistics"),
        },
        component: () =>
          import(
            /* webpackChunkName: "StatisticsView" */ "./views/StatisticsView.vue"
          ),
      },
      {
        path: "users",
        name: "UsersView",
        meta: {
          title: "Admin - " + i18n.t("users"),
        },
        component: () =>
          import(/* webpackChunkName: "UsersView" */ "./views/UsersView.vue"),
      },
      {
        path: "files",
        name: "FilesView",
        meta: {
          title: "Admin - " + i18n.t("filesAdmin"),
        },
        component: () =>
          import(/* webpackChunkName: "UsersView" */ "./views/FilesView.vue"),
      },
      {
        path: "new-users",
        name: "NewUsersView",
        meta: {
          title: "Admin - " + i18n.t("newUsers"),
        },
        component: () =>
          import(
            /* webpackChunkName: "UsersView" */ "./views/NewUsersView.vue"
          ),
      },
      {
        path: "subscriptions",
        name: "SubscriptionsView",
        meta: {
          title: "Admin - " + i18n.t("subscriptions"),
        },
        component: () =>
          import(
            /* webpackChunkName: "UsersView" */ "./views/SubscriptionsView.vue"
          ),
      },
    ],
  },
];

export default (router) => {
  routes.forEach(function (route) {
    router.addRoute(route);
  });
};
