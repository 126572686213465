<template>
  <div class="popup">
    <h2>{{ info.title }}</h2>
    <div v-html="info.fullText"></div>
  </div>
</template>

<script>
export default {
  props: {
    info: Object,
  },
};
</script>

<style lang="scss" scoped>
.popup {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #212b39;
  min-width: 40vw;
}
h2 {
  padding-bottom: 2rem;
}
</style>
