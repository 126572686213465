import Vue from "vue";
import VueRouter from "vue-router";

import PageNotFound from "../views/PageNotFound.vue";
import tokenService from "../modules/auth/services/token.service";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name:"PageNotFound",
    component: PageNotFound,
    beforeEnter(to, from, next) {
      if (to.name !== "Login" && tokenService.getUser() === "null")
        next({ name: "Login" });
      else next();
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
