<template>
  <div class="app-bar">
    <AppBarLang />
    <AppBarSocial />
    <AppBarNotifications />
    <AppBarBotStatus />
    <AppBarCart />
    <AppBarMenu />
  </div>
</template>

<script>
import AppBarLang from "../AppBar/AppBarLang.vue";
import AppBarSocial from "../AppBar/AppBarSocial.vue";
import AppBarNotifications from "../AppBar/AppBarNotifications.vue";
import AppBarBotStatus from "../AppBar/AppBarBotStatus.vue";
import AppBarCart from "../AppBar/AppBarCart.vue";
import AppBarMenu from "../AppBar/AppBarMenu.vue";

export default {
  components: {
    AppBarLang,
    AppBarSocial,
    AppBarNotifications,
    AppBarBotStatus,
    AppBarCart,
    AppBarMenu,
  },
 
};
</script>

<style lang="scss">
.app-bar {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    min-width: 0px !important;
    padding: 0px 4px !important;
    margin: 0px 2px !important;
  }
}
@media only screen and (max-width: 965px) {
  .app-bar {
    button {
      min-width: 0px !important;
      padding: 0px 1px !important;
      margin: 0px 1px !important;
    }
  }
}
</style>
