<template>
  <v-btn
    @click="$emit('click')"
    :color="color"
    v-bind="getButtonOptions"
    v-bind:class="[getButtonPositio, getButtonTypeStyles]"
  >
    {{ name }}</v-btn
  >
</template>

<script>
export default {
  name: "AppButton",
  props: {
    name: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: "right",
      validator(value) {
        // The value must match one of these strings
        return ["center", "left", "right"].includes(value);
      },
    },
    type: {
      type: String,
      default: "button",
      validator(value) {
        // The value must match one of these strings
        return ["text", "button", "auth"].includes(value);
      },
    },
    color: {
      type: String,
      default: "red",
    },
  },
  computed: {
    getButtonOptions() {
      if (this.type === "auth") {
        return { rounded: true, elevation: "0" };
      }
      if (this.type === "text") {
        return { text: true };
      } else return { elevation: "0" };
    },
    getButtonTypeStyles: function () {
      if (this.type === "auth") {
        return ["button--auth"];
      } else return "";
    },
    getButtonPositio: function () {
      if (this.position === "center") {
        return ["button--center"];
      }
      if (this.position === "right") {
        return ["button--right"];
      }
      if (this.position === "left") {
        return ["button--left"];
      } else return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.button--auth {
  width: 100%;
  font-weight: 700;
  border-radius: 2rem;
  box-shadow: none;
  text-transform: capitalize;
  span {
    color: white !important;
  }
}
.button--center {
  margin: auto;
}
.button--right {
  margin-left: auto;
}
.button--left {
  margin-right: auto;
}
</style>
