import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";
//import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import router from "../router/index";
import { getNewTokens } from "../services/setupInterceptors";
import TokenService from "../modules/auth/services/token.service";

class CallHub {
  constructor() {
    let url = "";
    if (process.env.VUE_APP_MODE == "development") {
      url = process.env.VUE_APP_BACKEND_URL;
    }

    try{
    this.client = new HubConnectionBuilder()
      .withUrl(url + "/userStream", {
        accessTokenFactory: () => {
          return TokenService.getLocalAccessToken();
        },
        withCredentials: true,
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      //.withHubProtocol(new MessagePackHubProtocol())
      .build();
    }
    catch(err){
      return console.error(err.toString());
    }

    this.connectionInterval = setInterval(
      async function () {
        try{
          if (this.client.connection._connectionState == "Disconnected") {
            await getNewTokens()
            this.start();
          }
        }
        catch(err){
          return console.error(err.toString());
        }
      }.bind(this),
      5000
    );
  }

  clearReconnectInterval() {
    clearTimeout(this.connectionInterval);
    return;
  }

  async start() {
    if (
      this.client.connection._connectionState != "Disconnected" ||
      router.currentRoute.name == "ChangePassword" ||
      router.currentRoute.name == "Login" ||
      router.currentRoute.name == "ConfirmEmail"
    ) return;

    try {
      await this.client.start();
      console.log("Connected!");
      this.client.invoke("GetConnectedUsers");
      this.client.invoke("GetConnectedBots");
    } catch (err) {
      return console.error(err.toString());
    }

  }

  stop() {
    if (this.client.connection._connectionState != "Disconnected") {
      this.client.connection.stop();
    }
  }

  async sendCommand(command, parameter) {
    if (parameter == undefined) {
      parameter = null;
    }
    if (this.client.connection._connectionState == "Disconnected") {
      this.client.start();
    } else {
      try {
        this.client.invoke("CommandFromUser", {
          Command: command,
          Parameter: parameter,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }

  sendCommandToUser(command, parameter, userID) {
    if (userID == undefined || userID == null) {
      console.log("userID must be specified!!!");
      return;
    }

    if (parameter == undefined) {
      parameter = null;
    }

    if (this.client.connection._connectionState == "Disconnected") {
      this.client.start().then(
        function () {
          console.log("Connected!");
          this.client.invoke("GetConnectedUsers");
          this.client.invoke("GetConnectedBots");
          this.client.invoke(
            "CommandForUser",
            {
              Command: command,
              Parameter: parameter,
            },
            userID
          );
        }.bind(this)
      );
    } else {
      this.client.invoke(
        "CommandForUser",
        {
          Command: command,
          Parameter: parameter,
        },
        userID
      );
    }
  }
}

export default new CallHub();
