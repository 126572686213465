import api from "@/services/api";
import TokenService from "@/modules/auth/services/token.service";
import router from "@/router";
import { store } from "@/store";

import { i18n } from "@/plugins/i18n";
import jwt_decode from "jwt-decode";

class AuthService {
  async login(payload) {
    try {
      const response = await api.post("/api/Auth/Login", {
        Email: payload.email,
        Password: payload.password,
      });

      payload.twoFactorEnabled = response.data.value.twoFactorEnabled;
      if (payload.twoFactorEnabled == false){
        await this.postLogin(response.data.value);
        return true;
      }
      return false;
    } catch (err) {
      store.dispatch("raiseNotification", {
        show: true,
        color: "error",
        text: err.response.data.error,
      });
      throw new Error(err);
    }
  }

  async postLogin(auth){
    TokenService.setUser(auth);

    const decoded = await jwt_decode(TokenService.getLocalAccessToken());
    store.dispatch("setRoles", decoded.Role);

    router.replace("/");
    store.dispatch("raiseNotification", {
      show: true,
      color: "success",
      text: i18n.t("loginSuccess"),
    });

    window.Moengage.add_unique_user_id(decoded.sub); // UNIQUE_ID is used to uniquely identify a user.
    window.Moengage.add_email(decoded.email);
    window.Moengage.track_event("login");
  }

  async twoFactorLogin(payload) {
    try {
      const response = await api.post("/api/Auth/TwoFactorAuthLogin", {
        Email: payload.email,
        TwoFactorToken: payload.twoFactorToken,
      });

      await this.postLogin(response.data.value);

    } catch (err) {
      store.dispatch("raiseNotification", {
        show: true,
        color: "error",
        text: i18n.t("wrong2FA"),
      });
      throw new Error(err);
    }
  }

  async register(payload) {
    try {
      await api.post("/api/Auth/RegisterV2", {
        // ActivationCode: payload.activationCode,
        Email: payload.email,
        Password: payload.password,
        ReferralCode: payload.referralCode,
      });
      window.Moengage.add_email(payload.email);
      window.Moengage.track_event("register");
    } catch (err) {
      store.dispatch("raiseNotification", {
        show: true,
        color: "error",
        text: err.response.data.error,
      });
    }
  }

  async logout() {
    TokenService.removeUser();
    router.replace("/login");
  }

  async getUserPersonalData() {
    try {
      const res = await api.post("/api/User/GetUserPersonalData");
      if (res != null && res.data.value != null) {
        const userInfo = await res.data.value;
        if (
          !userInfo ||
          userInfo.firstName == "" ||
          userInfo.lastName == "" ||
          userInfo.address == "" ||
          userInfo.post == "" ||
          userInfo.zip == "" ||
          userInfo.country == ""
        ) {
          if (!store.getters.isAdmin) {
            store.dispatch("setPersonalData", false);
          }
        } else {
          store.dispatch("setPersonalData", true);
          window.Moengage.add_first_name(userInfo.firstName);
          window.Moengage.add_last_name(userInfo.lastName);
        }
      }

      if (res.data.error != null) {
        store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: res.data.error,
        });
      }
      return res.data.value;
    } catch (error) {
      console.log(error);
      if (
        (error.response.status == 400 || error.response.status == 200) &&
        error.response.data.error != null
      ) {
        store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: error.response.data.error,
        });
      }
    }
  }
}

export default new AuthService();
