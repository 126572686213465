<template>
  <v-card width="800px">
    <v-container>
      <v-row justify="end">
        <v-icon class="pt-5 mr-5" @click="readNotification">mdi-close</v-icon>
      </v-row>
      <v-row justify="start">
        <span class="text-h5 ml-10">
          {{ data[0].title }}
        </span>
      </v-row>
    </v-container>
    <v-container>
      <div class="pa-8" v-html="data[0].fullText" />
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="readNotification()">
        {{ $t("ok") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/services/api";
export default {
  props: {
    data: Array,
  },
  methods: {
    async readNotification() {
      try {
        await api.post("api/User/ReadNotification", { Value: this.data[0].id });
        this.$emit("close");
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data.error,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #212b39;
  min-width: 40vw;
}
h2 {
  padding-bottom: 2rem;
}
</style>
