<template>
  <v-card width="800px">
    <v-container>
      <v-row justify="end">
        <v-icon class="pt-5 mr-5" @click="$emit('close')">mdi-close</v-icon>
      </v-row>
      <v-row justify="start">
        <span class="text-h5 ml-10">
          {{ $t("freeTrial3") }}
        </span>
      </v-row>
    </v-container>
    <v-container>
      <v-col class="pa-8">
        <p>
          {{ $t("freeTrialEnding1")}}<strong>{{ $d(freeTrialExpiryDate, "short") }}</strong>.<br /><br />
          {{ $t("freeTrialEnding2") }}"<strong>{{ $t("wantToContinue") }}</strong>".<br />
          {{ $t("freeTrialEnding3") }}<strong>{{ $d(freeTrialExpiryDate, "short") }}</strong>{{ $t("freeTrialEnding4") }}<br /><br />
          {{ $t("freeTrialEnding5") }}<strong>{{ $d(freeTrialExpiryDate, "short") }}</strong>{{ $t("freeTrialEnding6") }}<strong>{{ getExpiryDate }}</strong>.
        </p>
        <p style="text-decoration: underline">
          {{ $t("freeTrialEnding7") }}
        </p>
      </v-col>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color=" darken-1" text @click="$emit('close')">
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        color="success darken-1"
        text
        @click="wantToContinue"
      >
        {{ $t("wantToContinue") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/services/api";
export default {
  props: {
    freeTrialExpiryDate: String,
  },
  computed: {
    getExpiryDate() {
      var date = this.freeTrialExpiryDate;
      date.setDate(date.getDate() + 14);
      return this.$d(date, "short");
    },
  },
  methods:{
    async wantToContinue(){
      const res = await api.post("/api/User/CancelUserFreeTrial", null);
      
      try {
        if (res.data.error != null) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "error",
            text: res.data.error,
          });
        }
        else if (res.data.value == false){
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "error",
            text: this.$t("unknownError"),
          });
        }
        else if (res.data.value) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "success",
            text: this.$t("freeTrialCancelled"),
          });
          this.$emit("close");
        }
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data,
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: #212b39;
  min-width: 40vw;
}
h2 {
  padding-bottom: 2rem;
}
</style>
