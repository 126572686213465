import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import GBotIcon from "../assets/GBotIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      gbot: {
        component: GBotIcon,
      },
    },
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "white",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        background: "#212B39",
        lightBacground: "#324152",
      },
    },
  },
});
