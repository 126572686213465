<template>
  <div>
    <v-menu
      transition="slide-y-transition"
      bottom
      offset-y
      max-width="calc(100vw - 24px)"
      max-height="400px"
      :left="$vuetify.breakpoint.mdAndDown ? false : true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          max-width="40px"
          max-height="40px"
          icon
          v-bind="attrs"
          v-on="on"
          @click="getActive"
        >
          <v-badge
            class="notifications-menu__badge"
            color="pink"
            :value="getUnreadBanners"
            :content="getUnreadBanners"
          >
            <v-icon v-bind:class="{ 'notification-anim': getUnreadBanners }"
              >mdi-bell-outline
            </v-icon></v-badge
          >
        </v-btn>
      </template>
      <v-list color="#324152">
        <v-subheader>{{ $t("notifications") }}</v-subheader>

        <v-list-item
          class="notification"
          v-bind:class="{ notification__read: banner.isRead }"
          v-for="(banner, index) in banners"
          v-bind:key="index"
          @click="openPupup(banner)"
        >
          <v-list-item-avatar size="30">
            <v-icon :color="getColor(banner.priority)">{{
              getIcon(banner.priority)
            }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ banner.title }}</v-list-item-title>
            <v-list-item-subtitle>{{
              getFulltextString(banner.fullText)
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              <timeAgo
                v-if="getLang === 'slo'"
                :datetime="banner.start"
              ></timeAgo>
              <timeAgo v-else :datetime="banner.start" locale="en"></timeAgo>
            </v-list-item-action-text>
            <v-list-item-action-text
              ><v-btn icon color="error" @click.stop="close(banner)">
                <v-icon>mdi-window-close</v-icon>
              </v-btn></v-list-item-action-text
            >
          </v-list-item-action>
        </v-list-item>
        <v-list-item v-if="!banners.length" class="notification">
          <v-list-item-content>
            <v-list-item-subtitle>{{
              $t("noNotifications")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog width="max-content" v-model="showInfoPopup">
      <Info-popup :info="infoPopup" />
    </v-dialog>
  </div>
</template>

<script>
import InfoPopup from "../Popups/AppBarNotificationPopup.vue";
import api from "@/services/api";
export default {
  name: "AppBarNotifications",
  components: { InfoPopup },
  data() {
    return {
      banners: [],
      showBanners: false,
      infoPopup: [],
      showInfoPopup: false,
      classObject: {
        "min-width": "calc(100% - 24px)",
      },
    };
  },
  mounted() {
    this.getActive();
    this.getNotifications = setInterval(() => {
      this.getActive();
    }, 60 * 1 * 10000);
  },
  beforeDestroy() {
    clearInterval(this.getNotifications);
  },

  computed: {
    getUnreadBanners() {
      var read = this.banners.filter((v) => {
        if (!v.isRead) return v;
      });

      return read.length;
    },
    getLang() {
      if (localStorage.getItem("lang") === "sl-SI") return "slo";
      else return "en";
    },
  },
  methods: {
    close(v) {
      this.$cookies.set(v.id, "dismised", new Date(v.end));
      this.getActive();
    },
    read(v) {
      this.$cookies.set(v.id, "read", new Date(v.end));
      this.getActive();
    },
    getColor(v) {
      if (v === 0) {
        return "orange";
      }
      if (v === 1) {
        return "#4caf50";
      }
      if (v === 2) {
        return "red";
      }
      return "";
    },
    getIcon(v) {
      if (v === 0) {
        return "mdi-bullhorn-outline";
      }
      if (v === 1) {
        return "mdi-alert-circle";
      }
      if (v === 2) {
        return "mdi-alert-circle";
      }
      return "";
    },
    getFulltextString(text) {
      return text.replace(/<[^>]*>?/gm, " ");
    },
    openPupup(info) {
      this.read(info);
      this.infoPopup = info;
      this.showInfoPopup = true;
    },
    getActive() {
      api
        .post("/api/Banner/GetActive ")
        .then((response) => {
          if (response != null && response.data.value.length) {
            var banners = this.$cookies.keys().filter(Number);

            var dismised = banners.filter((v) => {
              if (this.$cookies.get(v) === "dismised") return v;
            });
            var read = banners.filter((v) => {
              if (this.$cookies.get(v) === "read") return v;
            });

            this.banners = response.data.value;
            this.banners = this.banners.filter(
              (item) => !dismised.includes(item.id.toString())
            );
            this.banners = this.banners.map((v) => {
              if (read.includes(v.id.toString())) return { ...v, isRead: true };
              return v;
            });

            this.showBanners = true;
          }
          if (response.data.error != null) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: response.data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            (error.response.status == 400 || error.response.status == 200) &&
            error.response.data.error != null
          ) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: error.response.data.error,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
.notification {
  width: 500px;
  border-bottom: solid 1px rgba(250, 250, 250, 0.151);
}
.notification__read {
  background: none;
  background: #00000018;
  opacity: 0.6;
}

.notifications-menu__badge {
  .v-badge__badge {
    margin-left: -2px;
    margin-bottom: -2px;
  }
}
.notification-anim {
  animation: spin 3s linear infinite;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(30deg);
  }
  4% {
    -moz-transform: rotate(-30deg);
  }
  8% {
    -moz-transform: rotate(30deg);
  }
  12% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(0deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  4% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  8% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  12% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 965px) {
  .notification {
    width: 100vw !important;
    max-width: 100%;
  }
}
</style>
