<template>
  <v-app :style="{ background: $vuetify.theme.themes.dark.background }">
    <AppNotification />
    <v-navigation-drawer
      class="lightBacground"
      floating
      v-model="drawer"
      app
      v-if="isLogedIn"
    >
      <AppNavigationDrawer />
    </v-navigation-drawer>
    <v-app-bar app v-if="isLogedIn" :style="{ background: '#324152' }">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <AppBar />
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <!--  <cookie-consent :cookieExpiryDays="365" style="background-color: #355b6d">
      <template slot="message">
        <div style="color: white">THIS WEBSITE USES COOKIES</div>
       
      </template>
      <template slot="button">
        <v-btn class="mx-5" color="success">ACCEPT</v-btn>
      </template>
    </cookie-consent> -->
    <CartDrawer />
    <v-dialog persistent v-model="popupDialog" max-width="800px">
      <NotificationPopup
        @close="
          popupDialog = false;
          $store.dispatch('setShowTutorial', true);
        "
        :data="popupData"
      />
    </v-dialog>
    <v-dialog v-model="updateModal" max-width="490" persistent>
      <v-card>
        <v-tabs-items v-model="updateTab">
          <v-tab-item value="updateAvailable">
            <v-card class="update-modal-card">
              <h2>{{ $t("updateAvailable") }}</h2>
              <p>
                {{ $t("updateInfo") }} <span>{{ versionCode }}</span>
              </p>
              <div>
                <v-btn color="#303D4D" @click="updateModal = false">{{
                  $t("notNow")
                }}</v-btn>
                <v-btn class="close_button" color="#303D4D" @click="updateBot()"
                  ><v-icon color="#22a652" dark
                    >mdi-monitor-arrow-down-variant
                  </v-icon>
                  {{ $t("update") }}</v-btn
                >
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="updateProgress">
            <v-card class="update-modal-card">
              <h2>{{ $t("downloading") }}</h2>
              <v-progress-linear
                v-model="updateProgress"
                color="blue-grey"
                height="25"
                class="mt-8 mb-6"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              <div>
                <v-btn color="#303D4D" @click="closeUpdateModal()">{{
                  $t("close")
                }}</v-btn>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="installProgress">
            <v-card class="update-modal-card">
              <h2>{{ $t("installing") }}</h2>
              <v-progress-linear
                v-model="updateProgress"
                color="blue-grey"
                height="25"
                class="mt-8 mb-6"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              <div>
                <v-btn color="#303D4D" @click="closeUpdateModal()">{{
                  $t("close")
                }}</v-btn>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="updateFailed">
            <v-card class="update-modal-card">
              <h2>{{ $t("updateFailed") }}</h2>
              <p>{{ $t("tryAgain") }}</p>
              <div>
                <v-btn color="#303D4D" @click="closeUpdateModal()">{{
                  $t("close")
                }}</v-btn>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="restarting">
            <v-card class="update-modal-card">
              <h2>{{ $t("updateComplete") }}</h2>
              <p>{{ $t("appRestart") }}</p>
              <div>
                <v-btn color="#303D4D" @click="closeUpdateModal()">{{
                  $t("close")
                }}</v-btn>
              </div>
            </v-card>
          </v-tab-item>

          <v-tab-item value="appStarted">
            <v-card class="update-modal-card">
              <h2>{{ $t("updateComplete") }}</h2>
              <p>{{ $t("appRestarted") }}</p>
              <div>
                <v-btn color="#303D4D" @click="closeUpdateModal()">{{
                  $t("close")
                }}</v-btn>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items></v-card
      >
    </v-dialog>
  </v-app>
</template>

<script>
import api from "@/services/api";
import callHub from "@/services/callHub";
import { mapState } from "vuex";

import { AppStatus } from "./helpers/appStatus.js";
import AppNotification from "./components/AppNotification.vue";
import AppNavigationDrawer from "./components/AppNavigationDrawer.vue";
import AppBar from "./components/AppBar/AppBar.vue";
import CartDrawer from "./components/CartDrawer.vue";
import NotificationPopup from "./components/NotificationPopup.vue";

export default {
  name: "App",
  components: {
    AppNotification,
    AppNavigationDrawer,
    AppBar,
    CartDrawer,
    NotificationPopup,
  },
  data() {
    return {
      drawer: null,
      timeout: 3000,
      updateModal: false,
      updateTab: "updateAvailable",
      updateProgress: 0,
      versionCode: "",
      popupDialog: false,
      popupData: {},
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.name == "Login") {
          callHub.stop();
          this.$store.dispatch("logout");
        }
        document.title = to.meta.title || "GBot";
      },
    },
    isPersonalDataCorrect() {
      this.getNotifications()
    },
  },

  async mounted() {
    if (!localStorage.getItem("lang")){
      localStorage.setItem("lang", "en-US");
      this.$i18n.locale = "en-US";
    }
    callHub.client.on("Ping", function () {});
    callHub.client.on("NotificationForUser", function (notification) {
      
      this.$store.dispatch("raiseNotification", {
        show: true,
        color: notification.success == true ? "success" : "error",
        text: notification.message
      });
    }.bind(this));

    callHub.client.on("AccountDataChanged", function () {});
    callHub.client.on("SpotAccountDataChanged", function () {});

    callHub.client.on(
      "BotUpdate",
      function (data) {
        this.versionCode = data;
        this.updateTab = "updateAvailable";
        this.updateModal = true;
      }.bind(this)
    );
    callHub.sendCommand(19);

    callHub.client.on(
      "AppStatus",
      function (status) {
        if (status == AppStatus.Started) {
          this.updateTab = "appStarted";
        } else if (status == AppStatus.Downloading) {
          this.updateProgress = 0;
          this.updateTab = "updateProgress";
        } else if (status == AppStatus.DownloadFailed) {
          callHub.client.off("UpdateProgress");
          this.updateTab = "updateFailed";
        } else if (status == AppStatus.Installing) {
          this.updateProgress = 0;
          this.updateTab = "installProgress";
        } else if (status == AppStatus.InstallFailed) {
          callHub.client.off("UpdateProgress");
          this.updateTab = "updateFailed";
        } else if (status == AppStatus.Restarting) {
          callHub.client.off("UpdateProgress");
          this.updateTab = "restarting";
        } else if (status == AppStatus.UpdateAvailable) {
          callHub.sendCommand(19);
        }
      }.bind(this)
    );
     
    if(this.isLogedIn){
      this.getNotifications();
      await callHub.start();
    }

    this.disconnectInterval = setInterval(
      function () {
        if (!this.isLogedIn) {
          callHub.clearReconnectInterval();
          clearTimeout(this.disconnectInterval);
          return;
        }
      }.bind(this),
      5000
    );
  },
  beforeDestroy() {
    callHub.client.off("AppStatus");
    callHub.client.off("UpdateProgress");
  },
  computed: {
    ...mapState(["notification"]),
    isLogedIn() {
      return this.$store.getters.loggedIn;
    },
    isPersonalDataCorrect() {
      return this.$store.getters.isPersonalDataCorrect;
    },
  },
  methods: {
    async getNotifications() {
      try {
        const res = await api.post("/api/User/GetPopupNotifications");
        if (res.data.value.length > 0) {
          this.popupData = res.data.value;
          if(this.isPersonalDataCorrect) this.popupDialog = true; 
        }
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data.error,
        });
      }
    },
    updateBot() {
      callHub.client.on(
        "UpdateProgress",
        function (progress) {
          if (this.updateTab == "updateProgress") {
            this.updateProgress = progress * 100;
          } else {
            this.updateProgress = progress;
          }
        }.bind(this)
      );

      this.updateTab = "updateProgress";
      callHub.sendCommand(15);
    },
    closeUpdateModal() {
      callHub.client.off("UpdateProgress");
      this.updateModal = false;
    },
    getTimeout() {
      if (!this.notification.timeout) {
        this.notification.timeout = this.timeout;
      }
      return this.notification.timeout;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

body {
  width: 100vw;
}
html {
  overflow-y: auto;
}

.v-application {
  font-family: "Montserrat", sans-serif !important;
}
.theme--dark.v-card {
  background-color: #2a3645 !important;
  color: #ffffff !important;
}
.v-tab--active {
  color: white !important;
}

.v-slide-group__content,
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #2a3645 !important;
}
.theme--dark.v-data-table,
.theme--dark.v-tabs-items,
.theme--dark.v-picker__body,
.theme--dark.v-toolbar.v-sheet {
  background-color: #324152 !important;
}

.theme--dark.v-card.v-card-pnl {
  background-color: #324152 !important;
}
::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background: #212b39;
}

::-webkit-scrollbar-thumb {
  background: rgb(204, 204, 204);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.page-title {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 12px 0 8px;
}
.editor svg > * {
  stroke: white !important;
}
.ql-picker-label::before {
  color: white !important;
}
.editor-lable {
  font-size: 1rem;
  margin-bottom: 0.3rem !important;
}
.update-modal-card {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  button {
    margin: 0.4rem;
  }
  p {
    padding: 0;
    margin-top: 20px;
  }
  .close_button {
    color: #22a652;
    font-weight: 700;
    i {
      font-size: 1rem;
      margin-right: 4px;
    }
  }
}
</style>
