<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn max-width="40px" max-height="40px" icon v-bind="attrs" v-on="on">
          <v-icon :color="isBotOnline ? 'green' : 'red'">
            {{ isBotOnline ? "mdi-wifi" : "mdi-wifi-off" }}
          </v-icon>
        </v-btn>
      </template>

      <span>
        Bot {{ $t("is") }} {{ isBotOnline ? $t("online") : $t("offline") }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import api from "@/services/api";
import moment from "moment";

export default {
  name: "AppBarBotStatus",

  mounted() {
    this.isBotOnlineInterval = setInterval(
      function () {
        if (!this.loggedIn) {
          clearTimeout(this.isBotOnlineInterval);
          return;
        }
        this.getActiveBot();
      }.bind(this),
      60 * 5 * 1000
    );
    this.getActiveBot();
  },
  computed: {
    isBotOnline() {
      if (this.$store.getters.getLastBotPing == null) {
        return this.getActiveBot();
      } else {
        if (
          this.activeBot == null ||
          this.activeBot.LastPing <
            moment(new Date()).subtract(15, "minutes").toDate()
        ) {
          return false;
        }
        return true;
      }
    },
  },
  methods: {
    getActiveBot() {
      api
        .post("/api/TradingBot/GetActiveBot")
        .then((response) => {
          if (response != null && response.data.value != null) {
            var bot = response.data.value;
            this.activeBot = {
              ID: bot.id,
              BotCode: bot.code,
              Blocked: bot.blocked,
              LastPing: moment(bot.lastPing).toDate(),
              VersionCode: bot.versionCode,
            };
            this.$store.dispatch("setBotLastPing", this.activeBot.LastPing);
          } else if(response.data.value == null) {
            this.$store.dispatch('setShowTutorial', true);
          }
          if (response.data.error != null) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: response.data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
