import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    notification: {
      show: false,
      color: "success",
      text: "",
      timeout: 3000,
    },
    showTutorial: false,
    lastBotPing: null,
  },
  getters: {
    getShowTutorial(state) {
      return state.showTutorial;
    },
    getLastBotPing(state) {
      return state.lastBotPing;
    },
  },

  mutations: {
    raiseNotification(state, notificationInfo) {
      state.notification = notificationInfo;
    },
    setShowTutorial(state, data) {
      state.showTutorial = data;
    },
    botLastPing(state, lastPing) {
      state.lastBotPing = lastPing;
    },
  },
  actions: {
    raiseNotification(context, notificationInfo) {
      context.commit("raiseNotification", notificationInfo);
    },
    setShowTutorial(context, data) {
      context.commit("setShowTutorial", data);
    },
    setBotLastPing(context, lastPing) {
      context.commit("botLastPing", lastPing);
    },
  },
  modules: {},
});
