<template>
  <v-container class="d-flex px-3 flex-column">
    <div v-if="showSummary">
      <p class="my-4">{{ $t("paypal") }}</p>
      <p class="my-4">
        {{ $t("value") }}
        <span style="float: right">{{ getLocalNumber(value) }} €</span>
      </p>
      <v-divider class="ma-0" />
      <p class="my-4">
        {{ $t("VAT") }}:
        <span style="float: right">{{ getLocalNumber(total - value) }} €</span>
      </p>
      <v-divider class="ma-0" />
      <p class="my-4">
        {{ $t("summary") }}:
        <span style="float: right">{{ getLocalNumber(total) }} €</span>
      </p>
    </div>

    <div class="alert alert-danger" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <v-btn text v-if="showBackButton" @click="backButton" class="my-2">
      {{ $t("back") }}
    </v-btn>
    <form>
      <div id="paypal-button" ref="paypalButton"></div>
    </form>
  </v-container>
</template>

<script>
import braintree from "braintree-web";

export default {
  name: "PayPal",
  components: {},
  props: {
    authToken: {
      value: String,
    },
    value: Number,
    total: Number,
    showBackButton: Boolean,
    showSummary: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
      errorMessage: "",
      clientInstance: "",
      paypalCheckoutInstance: "",
    };
  },
  created() {
    this.createBrainTree();
  },
  methods: {
    getLocalNumber(v) {
      if (localStorage.getItem("lang") === "en-US") {
        return v.toFixed(2);
      } else {
        return parseFloat(v).toFixed(2).toString().replace(".", ",");
      }
    },
    backButton() {
      this.$emit("back");
      this.back();
    },
    back() {
      document.querySelector("#paypal-button").replaceChildren("");
      this.teardownPayPal();
      this.errorMessage = "";
      this.clientInstance = "";
      this.paypalCheckoutInstance = "";
    },
    createBrainTree() {
      braintree.client.create(
        {
          authorization: this.authToken,
        },
        (clientErr, clientInstance) => {
          if (clientErr) {
            this.errorMessage =
              "There was an error setting up the client instance. Message: " +
              clientErr.message;
            this.$emit("brainTreePayPalError", this.errorMessage);
            return;
          } else {
            this.clientInstance = clientInstance;
            this.paypalCheckoutCreate();
            this.dataCollectorCreate();
          }
        }
      );
    },
    dataCollectorCreate() {
      braintree.dataCollector.create(
        {
          client: this.clientInstance,
          kount: true,
        },
        (dataCollectorErr, dataCollectorInstance) => {
          if (dataCollectorErr) {
            this.errorMessage =
              "There was an error setting up the data collector! Message: " +
              dataCollectorErr.message;
            this.$emit("brainTreePayPalError", this.dataCollectorErr);
            return;
          }
          //TODO: Anže what is this? -> device.data.payload
          this.$emit("deviceDataPayload", dataCollectorInstance.deviceData);
        }
      );
    },
    teardownPayPal() {
      if (this.paypalCheckoutInstance == undefined) {
        return;
      }

      this.paypalCheckoutInstance.teardown((teardownErr) => {
        if (teardownErr) {
          this.errorMessage =
            "There was an error tearing it down! Message: " +
            teardownErr.message;
          this.$emit("brainTreePayPalError", this.errorMessage);
          return;
        } else {
          this.paypalCheckoutInstance = "";
          return;
        }
      });
    },
    paypalCheckoutCreate() {
      braintree.paypalCheckout.create(
        {
          client: this.clientInstance,
        },
        (paypalErr, checkoutInstance) => {
          if (paypalErr) {
            this.errorMessage =
              "There was an error setting up paypal button! Message: " +
              paypalErr.message;
            this.$emit("brainTreePayPalError", this.paypalErr);
            return;
          }

          this.paypalCheckoutInstance = checkoutInstance;

          this.paypalCheckoutInstance.loadPayPalSDK(
            {
              vault: true,
              currency: "EUR",
            },
            function (loadSDKErr) {
              // The PayPal script is now loaded on the page and
              // window.paypal.Buttons is now available to use
              if (loadSDKErr) {
                console.log("loadSDKErr: " + JSON.stringify(loadSDKErr));
                return;
              }

              window.paypal
                .Buttons({
                  style: {
                    layout: "vertical",
                    color: "silver",
                    shape: "rect",
                    label: "checkout",
                  },
                  fundingSource: window.paypal.FUNDING.PAYPAL,

                  createBillingAgreement: () =>
                    this.paypalCreateBillingAgreement(),
                  onApprove: (data, actions) =>
                    this.paypalOnApprove(data, actions),
                  onCancel: (data) => this.paypalOnCancel(data),
                  onError: (error) => this.paypalOnError(error),
                })
                .render("#paypal-button")
                .then(function () {
                  // The PayPal button will be rendered in an html element with the ID
                  // `paypal-button`. This function will be called when the PayPal button
                  // is set up and ready to be used
                });
            }.bind(this)
          );

          this.$emit("payPalButtonReady");
        }
      );
    },
    paypalCreateBillingAgreement() {
      let options = {
        flow: "vault", // Required
      };
      console.log("createBillingAgreement > options: ", options);
      return this.paypalCheckoutInstance.createPayment(options);
    },
    paypalOnCancel(data) {
      console.log(
        "onCancel > PayPal payment canceled",
        JSON.stringify(data, 0, 2)
      );
    },
    paypalOnError(err) {
      console.error("onError > PayPal error", err);
    },
    async paypalOnApprove(data, actions) {
      console.log(
        "onApprove > paypalCheckoutInstance: " +
          JSON.stringify(this.paypalCheckoutInstance)
      );
      console.log("onApprove > actions: " + JSON.stringify(actions));
      return this.paypalCheckoutInstance.tokenizePayment(data, (err, payload) =>
        this.paypalApproved(err, payload)
      );
    },
    async paypalApproved(err, payload) {
      if (err) {
        console.log("approved > err: " + JSON.stringify(err));
        return;
      }

      this.tokenizePaypalPayload = payload;
      this.$emit("paypalPaymentSubmit", payload.nonce, null);
      this.teardownPayPal();
    },
  },
};
</script>
