import en from "@/assets/lang/en.json";
import sl from "@/assets/lang/sl.json";
import Vue from "vue";
import VueI18n from "vue-i18n";

const dateTimeFormats = {
  sl: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortTime: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
    small: {
      year: "numeric",
      month: "short",
    },
    day: {
      month: "short",
      day: "numeric",
    },
    month: {
      month: "short",
    },
  },
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    shortTime: {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
    small: {
      year: "numeric",
      month: "short",
    },
    day: {
      month: "short",
      day: "numeric",
    },
    month: {
      month: "short",
    },
  },
};

const numberFormats = {
  sl: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    number: {},
  },
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
    number: {},
  },
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "sl-SI",
  messages: {
    en: en,
    sl: sl,
  },
  dateTimeFormats,
  numberFormats,
  silentTranslationWarn: true,
});
