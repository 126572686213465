<template>
  <div class="d-flex flex-column fill-height">
    <v-toolbar-title class="flex-shrink-0">
      <a :href="getLandingUrl"> <img src="@/assets/logo.png" class="logo" /></a>
    </v-toolbar-title>
    <v-list-group dense v-if="$store.getters.isAdmin">
      <template v-slot:activator>
        <v-subheader class="white--text text-uppercase admin__button">{{
          $t("administrator")
        }}</v-subheader>
      </template>
      <v-list-item
        class="white--text"
        v-for="(item, index) in adminLinks"
        :key="index"
        :to="item.to"
        link
      >
        <v-list-item>{{ item.title }}</v-list-item>
      </v-list-item>
    </v-list-group>
    <v-list-group dense>
      <template v-slot:activator>
        <v-subheader class="white--text text-uppercase admin__button">{{
          $t("globalStatistics")
        }}</v-subheader>
      </template>
      <v-list-item
        class="white--text"
        v-for="(item, index) in globalStatisticsLinks"
        :key="index"
        :to="item.to"
        link
      >
        <v-list-item>{{ item.title }}</v-list-item>
      </v-list-item>
    </v-list-group>
    <v-list-group dense>
      <template v-slot:activator
        ><v-subheader class="white--text text-uppercase admin__button"
          >Futures</v-subheader
        >
      </template>

      <v-list-item
        class="white--text"
        v-for="(item, index) in futuresLinks"
        :key="index"
        :to="item.to"
        link
      >
        <v-list-item>{{ item.title }}</v-list-item>
      </v-list-item>
    </v-list-group>
    <v-list-group dense>
      <template v-slot:activator>
        <v-subheader class="white--text text-uppercase admin__button"
          >Spot</v-subheader
        >
      </template>

      <v-list-item
        class="white--text"
        v-for="(item, index) in spotLinks"
        :key="index"
        :to="item.to"
        link
      >
        <v-list-item>{{ item.title }}</v-list-item>
      </v-list-item>
    </v-list-group>
    <div v-if="loggedIn" class="online-data">
      <v-tooltip top v-if="showFreeTrial">
        <template v-slot:activator="{ on, attrs }">
          <v-card v-bind="attrs" v-on="on" class="ma-1">
            <p class="text-center">
              {{ $t("freeTrial") }}
              {{ getFreeTrialDaysRemaining }}
              {{ $t("freeTrial1") }}
            </p>
          </v-card>
        </template>
        <span>
          {{ $t("freeTrial2")
          }}{{
            freeTrialExpiryDate == ""
              ? ""
              : $d(new Date(freeTrialExpiryDate), "short")
          }}</span
        >
      </v-tooltip>
    </div>
    <v-row dense class="pt-0 mb-2">
      <v-col class="text-center">
        <v-btn color="success" @click="showSubscriptions">{{
          $t("subscriptions")
        }}</v-btn>
      </v-col>
    </v-row>
    <AppFooter class="mt-auto" />

    <v-dialog
      @click:outside="freeTrialPopupDialog = false"
      v-model="freeTrialPopupDialog"
      max-width="800px"
    >
      <FreeTrialPopup
        @close="freeTrialPopupDialog = false"
        :freeTrialExpiryDate="freeTrialExpiryDate"
      />
    </v-dialog>
    <v-dialog persistent v-model="freeTrialEndedPopupDialog" max-width="800px">
      <FreeTrialEndedPopup
        @close="freeTrialEndedPopupDialog = false"
        :freeTrialExpiryDate="freeTrialExpiryDate"
      />
    </v-dialog>
    <v-dialog v-model="subscriptionsModal" max-width="1200px">
      <SubscriptionsDialog @close="subscriptionsModal = false" />
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/api";

import AppFooter from "./AppFooter.vue";

import FreeTrialPopup from "./Popups/FreeTrialPopup.vue";
import FreeTrialEndedPopup from "./Popups/FreeTrialEndedPopup.vue";
import SubscriptionsDialog from "./Popups/SubscriptionsDialog.vue";

export default {
  components: {
    AppFooter,
    SubscriptionsDialog,
    FreeTrialEndedPopup,
    FreeTrialPopup,
  },
  data() {
    return {
      showFreeTrial: false,
      freeTrialPopupDialog: false,
      freeTrialEndedPopupDialog: false,
      subscriptionsModal: false,
      freeTrialDaysRemaining: "",
      freeTrialExpiryDate: "",

      adminLinks: [
        { title: this.$t("statistics"), to: "/admin/statistics" },
        { title: this.$t("receipts"), to: "/admin/payments" },
        { title: this.$t("activationCode"), to: "/admin/activation-codes" },
        { title: this.$t("licenses"), to: "/admin/licenses" },
        { title: this.$t("referral"), to: "/admin/referrals" },
        { title: this.$t("botVersion"), to: "/admin/bot-versions" },
        { title: this.$t("banners"), to: "/admin/banners" },
        { title: this.$t("users"), to: "/admin/users" },
        { title: this.$t("manualTrade"), to: "/admin/manual-trades" },
        { title: this.$t("reportedBugs"), to: "/admin/reported-bugs" },
        { title: this.$t("faq0"), to: "/admin/add-faqs" },
        { title: this.$t("settings"), to: "/admin/global-settings" },
        { title: this.$t("filesAdmin"), to: "/admin/files" },
        { title: this.$t("newUsers"), to: "/admin/new-users" },
        { title: this.$t("subscriptions"), to: "/admin/subscriptions" },
      ],
      globalStatisticsLinks: [
        {
          title: "Futures",
          to: "/global-statistics",
        },
        {
          title: "Spot",
          to: "/spot/global-statistics",
        },
      ],
      futuresLinks: [
        { title: this.$t("info"), to: "/" },
        { title: this.$t("bots"), to: "/bots" },

        { title: this.$t("tradeHistory"), to: "/trading" },
      ],
      spotLinks: [
        { title: this.$t("info"), to: "/spot/info" },
        { title: this.$t("bots"), to: "/spot/bots" },
        { title: this.$t("tradeHistory"), to: "/spot/trading" },
      ],
    };
  },
  mounted() {
    this.checkForFreeTrial();
  },
  computed: {
    getLandingUrl() {
      const landingUrl = window.location.host.split(".");
      landingUrl.shift();
      if (landingUrl[1]) return "https://" + landingUrl.join(".");
      else return "/";
    },
    isAdmin() {
      if (this.$store.getters.isAdmin == null) {
        return this.setAdmin();
      } else {
        return this.$store.getters.isAdmin;
      }
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    getFreeTrialDaysRemaining() {
      if (parseInt(this.freeTrialDaysRemaining) < 0) return 0;
      return this.freeTrialDaysRemaining;
    },
  },
  methods: {
    showSubscriptions() {
      this.subscriptionsModal = true;
      window.Moengage.track_event("show_subscriptions");
    },
    async checkForFreeTrial() {
      try {
        const res = await api.post("/api/User/GetFreeLicenceTimeRemaining");
        if (res.data.value == null) {
          return;
        }

        this.freeTrialDaysRemaining = parseInt(res.data.value.daysRemaining);
        this.freeTrialExpiryDate = new Date(res.data.value.expiryDate);
        this.showFreeTrial = true;

        if (
          res.data.value.trialEnded &&
          !this.$cookies.get("showFreeTrialEndedPopup")
        ) {
          this.freeTrialEndedPopupDialog = true;
        }
        if (
          this.freeTrialDaysRemaining == 5 ||
          this.freeTrialDaysRemaining == 3 ||
          this.freeTrialDaysRemaining == 1 ||
          this.freeTrialDaysRemaining == 0
        ) {
          if (!this.$cookies.get("showFreeTrialPopup")) {
            this.freeTrialPopupDialog = true;
            this.$cookies.set("showFreeTrialPopup", false, 60 * 60 * 24);
          }
        }
      } catch (err) {
        console.log();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-height {
  height: 100%;
  background: #324152;
}
.logo {
  margin: 18px 18px 25px;
  height: 30px;
}
::-webkit-scrollbar-track {
  background: #324152 !important;
}
.v-list-item {
  min-height: 40px;
  padding: 0 8px;
  margin: 0 8px;
  margin-bottom: 4px;
  border-radius: 4px;
}
.v-list-group {
  padding: 0 4px;
}
.v-list-group__header {
  padding: 0 8px !important;
}
.v-list--nav {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.admin__button {
  padding: 0 !important;
  margin-left: -8px;
  width: 100%;
}
.v-subheader {
  font-size: 0.875rem !important;
}
.v-list-item--active {
  .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: #4caf50 !important;
    font-weight: 700 !important;
  }
}

.v-list-item--link:before {
  background-color: rgba(255, 255, 255, 0.221) !important;
}
.v-list-item--link:hover {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.online-data {
  flex: 100;
  font-size: 0.8rem;
  margin-top: auto;
  margin-bottom: 1.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: auto;
  }
  p {
    padding: 0.5rem 1.5rem;
    margin: 0;
  }
  .online-data__title {
    text-align: center;
  }
}

@media only screen and (max-width: 960px) {
  .login {
    margin: 2rem 0.5rem 0;
    height: 40px;
  }
}
</style>
