<template>
  <v-card class="pa-4">
    <h2 class="subscriptions-title">{{ $t("ready") }}</h2>
    <h4 class="subscriptions-subtitle">{{ $t("chose") }}</h4>
    <p class="subscriptions-text ma-0 mt-4">
      {{ $t("subscriptionFistStep") }}
    </p>
    <v-row align="center" justify="center" class="my-2">
      <v-btn-toggle v-model="billingType" mandatory>
        <v-btn class="billing-button"> {{ $t("monthly") }} </v-btn>
        <v-btn class="billing-button"> {{ $t("3month") }} </v-btn>
        <v-btn class="billing-button"> {{ $t("6month") }} </v-btn>
        <v-btn class="billing-button"> {{ $t("yearly") }} </v-btn>
      </v-btn-toggle>
    </v-row>
    <p class="subscriptions-text ma-0">{{ $t("subscriptionSecondStep") }}</p>
    <v-container v-if="!loading">
      <v-row>
        <v-col>
          <div class="price-card">
            <p class="title">Lite</p>

            <div class="price">
              <p>{{ $t("startingAt") }}</p>
              <p>
                <span>{{ getLocalNumber(getLiteItem.price) }}</span>
                € {{ getBillingType }}.
              </p>
            </div>
            <div class="features">
              <p>
                <strong>{{ $t("includes") }}</strong>
              </p>
              <ul>
                <li>3 spot {{ $t("3Bots") }}</li>
              </ul>
            </div>
            <v-spacer> </v-spacer>
            <v-btn
              v-if="
                isButtonActive(getLiteItem.order) &&
                !isButtonActiveExtend(getLiteItem.order)
              "
              color="success"
              class="start-free-trial"
              >{{ $t("active") }}
            </v-btn>
            <v-btn
              v-if="
                isButtonActive(getLiteItem.order) &&
                isButtonActiveExtend(getLiteItem.order)
              "
              color="success"
              @click="handleButtonLite(getLiteItem)"
              class="start-free-trial"
              >{{ $t("extend") }}
            </v-btn>
            <v-btn
              v-if="
                !isButtonActive(getLiteItem.order) &&
                !isButtonActiveExtend(getLiteItem.order)
              "
              :disabled="isButtonDisabled(getLiteItem.order)"
              @click="handleButtonLite(getLiteItem)"
              color="success"
              class="start-free-trial"
              >{{
                isButtonUpgrade(getLiteItem) ? $t("select") : $t("upgrade")
              }}
              Lite</v-btn
            >
          </div>
        </v-col>
        <v-col>
          <div class="price-card">
            <p class="title">Standard</p>

            <div class="price">
              <p>{{ $t("startingAt") }}</p>
              <p>
                <span>{{ getLocalNumber(getStandardItem.price) }}</span>
                € {{ getBillingType }}.
              </p>
            </div>
            <div class="features">
              <p>
                <strong>{{ $t("includes") }}</strong>
              </p>
              <ul>
                <li>10 spot {{ $t("mBots") }}</li>
              </ul>
            </div>
            <v-spacer> </v-spacer>
            <v-btn
              v-if="
                isButtonActive(getStandardItem.order) &&
                isButtonActiveExtend(getStandardItem.order)
              "
              color="success"
              class="start-free-trial"
              >{{ $t("active") }}
            </v-btn>
            <v-btn
              v-if="
                isButtonActive(getStandardItem.order) &&
                !isButtonActiveExtend(getStandardItem.order)
              "
              color="success"
              @click="handleButtonStandart(getStandardItem)"
              class="start-free-trial"
              >{{ $t("extend") }}
            </v-btn>
            <v-btn
              v-if="
                !isButtonActive(getStandardItem.order) &&
                !isButtonActiveExtend(getStandardItem.order)
              "
              :disabled="isButtonDisabled(getStandardItem.order)"
              @click="handleButtonStandart(getStandardItem)"
              color="success"
              class="start-free-trial"
              >{{
                isButtonUpgrade(getStandardItem.order)
                  ? $t("select")
                  : $t("upgrade")
              }}
              Standard</v-btn
            >
          </div>
        </v-col>
        <v-col>
          <div class="price-card">
            <p class="title">Pro</p>

            <div class="price">
              <p>{{ $t("startingAt") }}</p>
              <p>
                <span>{{ getLocalNumber(getProItem.price) }}</span>
                € {{ getBillingType }}.
              </p>
            </div>
            <div class="features">
              <p>
                <strong>{{ $t("includes") }}</strong>
              </p>
              <ul>
                <li>{{ $t("unlimitedBots") }}</li>
              </ul>
            </div>
            <v-spacer> </v-spacer>

            <v-btn
              v-if="
                isButtonActive(getProItem.order) &&
                !isButtonActiveExtend(getProItem.order)
              "
              color="success"
              class="start-free-trial"
              >{{ $t("active") }}
            </v-btn>
            <v-btn
              v-if="
                isButtonActive(getProItem.order) &&
                isButtonActiveExtend(getProItem.order)
              "
              color="success"
              @click="handleButtonPro(getProItem)"
              class="start-free-trial"
              >{{ $t("extend") }}
            </v-btn>
            <v-btn
              v-if="
                !isButtonActive(getProItem.order) &&
                !isButtonActiveExtend(getProItem.order)
              "
              :disabled="isButtonDisabled(getProItem.order)"
              @click="handleButtonPro(getProItem)"
              color="success"
              class="start-free-trial"
              >{{
                isButtonUpgrade(9) ? $t("select") : $t("upgrade")
              }}
              Pro</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row align="center" justify="center" class="my-5">{{
        $t("loadingPrices")
      }}</v-row>
      <v-row align="center" justify="center" class="my-5"
        ><v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular></v-row
    ></v-container>
    <v-dialog v-model="subscriptionUpgradePopup" max-width="450">
      <v-card
        ><v-container
          v-if="!subscriptionUpgradeLoading"
          class="subscription-upgrade"
        >
          <p class="title-text">{{ subscriptionUpgradeData.titleText }}</p>
          <p class="total">
            {{ subscriptionUpgradeData.total }} €
            <span
              ><p class="span">
                {{ subscriptionUpgradeData.billingText }}
              </p></span
            >
          </p>
          <p>
            {{ subscriptionUpgradeData.planText }}
            <span>{{ subscriptionUpgradeData.subtotal }} €</span>
          </p>
          <p class="description-text">
            {{ subscriptionUpgradeData.descriptionText }}
          </p>
          <p class="subtotal">
            {{ $t("subTotal") }}
            <span>{{ subscriptionUpgradeData.subtotal }} €</span>
          </p>
          <hr />
          <p class="tax">
            VAT (22 %)
            <span>{{ subscriptionUpgradeData.tax }} €</span>
          </p>
          <hr />
          <p>
            {{ $t("todayTotal") }}
            <span>{{ subscriptionUpgradeData.total }} €</span>
          </p>
          <v-btn color="success" class="button" @click="handleUpgrade"
            >{{ $t("upgrade") }}{{ subscriptionUpgradeData.planText }}
          </v-btn>
          <p class="button-subtitle">{{ $t("buttonSubtitle") }}</p>
        </v-container>
        <v-container v-else>
          <v-row align="center" justify="center" class="my-5">{{
            $t("loadingPrices")
          }}</v-row>
          <v-row align="center" justify="center" class="my-5"
            ><v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></v-row></v-container></v-card
    ></v-dialog>
    <v-dialog v-model="subscriptionUpgradePopup" max-width="450">
      <SubscriptionsPaymentDialog
        :subscription="curentSubscription"
        @close="CloseSubscriptionUpgradePopup"
        :isOpen="subscriptionUpgradePopup"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import api from "@/services/api";
import SubscriptionsPaymentDialog from "./SubscriptionsPaymentDialog.vue";
export default {
  data() {
    return {
      billingType: 3,
      subscriptionProductsLite: [],
      subscriptionProductsStandard: [],
      subscriptionProductsPro: [],
      loading: true,
      activeSubscription: {},
      subscriptionUpgradePopup: false,
      subscriptionUpgradeLoading: true,
      subscriptionUpgradeData: {},
      subscriptionsPaymentDialog: false,
      curentSubscription: {},
    };
  },
  mounted() {
    this.getSubscriptionProducts();
  },
  computed: {
    getLiteItem() {
      if (this.subscriptionProductsLite.length > 0) {
        return this.subscriptionProductsLite[this.billingType];
      }
      return "";
    },
    getStandardItem() {
      if (this.subscriptionProductsStandard.length > 0) {
        return this.subscriptionProductsStandard[this.billingType];
      }
      return "";
    },
    getProItem() {
      if (this.subscriptionProductsPro.length > 0) {
        return this.subscriptionProductsPro[this.billingType];
      }
      return "";
    },
    getBillingType() {
      return this.$t("mo");
    },
  },
  methods: {
    getLocalNumber(v) {
      if (localStorage.getItem("lang") === "en-US") {
        return v.toFixed(2);
      } else {
        v.toString().replace(".", ",");
        return parseFloat(v).toFixed(2);
      }
    },
    isButtonDisabled(order) {
      if (!this.activeSubscription.length) return false;
      if (this.activeSubscription[0].order < order) return false;
      else return true;
    },
    isButtonUpgrade(order) {
      if (!this.activeSubscription.length) return true;
      if (this.activeSubscription[0].order < order) return false;
      else return true;
    },
    isButtonActive(order) {
      if (!this.activeSubscription.length) return false;
      if (this.activeSubscription[0].order == order) return true;
      else return false;
    },
    isButtonActiveExtend(order) {
      if (!this.activeSubscription.length) return false;
      if (
        this.activeSubscription[0].order == order &&
        this.activeSubscription[0].canExtend == true
      )
        return true;
      else return false;
    },

    async getSubscriptionProducts() {
      try {
        const res = await api.post("/api/Subscription/GetSubscriptionProducts");
        const data = res.data.value;
        this.activeSubscription = await data.filter(
          (item) => item.isActive == true
        );
        this.subscriptionProductsLite = await data.filter(
          (item) => item.name === "Lite"
        );
        this.subscriptionProductsStandard = await data.filter(
          (item) => item.name === "Standard"
        );
        this.subscriptionProductsPro = await data.filter(
          (item) => item.name === "Pro"
        );
        this.subscriptionProductsLite.sort((a, b) => {
          return a.billingCode - b.billingCode;
        });
        this.subscriptionProductsStandard.sort((a, b) => {
          return a.billingCode - b.billingCode;
        });
        this.subscriptionProductsPro.sort((a, b) => {
          return a.billingCode - b.billingCode;
        });
        this.loading = false;
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data.error,
        });
      }
    },
    async handleButtonLite(item) {
      this.curentSubscription = item;
      this.subscriptionUpgradePopup = true;
    },
    async handleButtonStandart(item) {
      this.curentSubscription = item;
      this.subscriptionUpgradePopup = true;
    },
    async handleButtonPro(item) {
      this.curentSubscription = item;
      this.subscriptionUpgradePopup = true;
    },
    CloseSubscriptionUpgradePopup(successful) {
      this.curentSubscription = {};
      this.subscriptionUpgradePopup = false;
      if (successful) this.$emit("close");
    },
    async handleUpgrade() {
      try {
        await api.post("/api/Checkout/ConfirmSubscriptionChange", {
          Value: JSON.stringify(this.subscriptionUpgradeData),
        });
      } catch (err) {
        this.$store.dispatch("raiseNotification", {
          show: true,
          color: "error",
          text: err.response.data,
        });
      }
    },
  },
  components: { SubscriptionsPaymentDialog },
};
</script>

<style lang="scss">
.subscriptions-title,
.subscriptions-subtitle {
  text-align: center;
  margin: 0.3rem;
}
.subscriptions-text {
  font-size: 15px;
  text-align: center;
}
.billing-button {
  background: #2a3645 !important;
  border: none !important;
}
.price-card {
  background-color: #ffffff0a !important;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    font-size: 26px !important;
  }
  .subtitle {
    text-align: center;
    margin-bottom: 20px;
  }
  .price {
    text-align: center;
    p:first-child {
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.596);
      margin-bottom: -6px;
    }
    span {
      font-size: 40px;
    }
  }
  .features {
    text-align: left;
    ul {
      padding: 0 !important;
    }
    li {
      line-height: 1.8;
    }
    p {
      margin-left: -18px;
    }
  }
  .start-free-trial {
    margin-top: 40px;
  }
}
.subscription-upgrade {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  hr {
    margin: 10px 0;
    background: rgba(255, 255, 255, 0.027);
  }
  p {
    margin: 0;
    font-weight: 500;
    font-size: 0.9rem;
  }

  span {
    float: right;
  }

  .title-text {
    font-weight: 600;
    opacity: 0.7;
  }
  .total {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    font-size: 2rem;
    span {
      float: none;
      p {
        margin-left: 6px;
        font-size: 0.8rem;
        width: 60px;
      }
    }
  }
  .description-text {
    font-size: 0.8rem;
    opacity: 0.7;
  }
  .subtotal {
    margin-top: 30px;
  }
  .tax {
    opacity: 0.7;
  }
  .button {
    margin: 1rem auto;
    width: min-content;
  }
  .button-subtitle {
    opacity: 0.8;
    font-weight: 400;
    font-size: 0.6rem;
    text-align: center;
  }
}
</style>
