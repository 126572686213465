<template>
  <v-card width="800px" class="mt5">
    <v-card-title>
      <span class="text-h5">{{ $t("personalData") }}</span>
    </v-card-title>
    <v-card-text v-if="additionalText" class="pl-7 pt-5">
      {{ $t("accountInformation") }}
    </v-card-text>
    <v-card-text>
      <v-form v-model="isFormValid" ref="form">
        <v-container>
          <v-row dense>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="userInfo.firstName"
                :label="this.$t('name')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="userInfo.lastName"
                :label="this.$t('surname')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="pt-0 mt-0">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="userInfo.address"
                :label="this.$t('address')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="pt-0 mt-0">
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="userInfo.zip"
                :label="this.$t('zip')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="userInfo.post"
                :label="this.$t('city')"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="pt-0 mt-0">
            <v-col cols="6" sm="6" md="6">
              <v-select
                v-model="userInfo.countryID"
                :items="countries"
                item-text="name"
                item-value="id"
                :label="this.$t('country')"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="savePersonalData">
        {{ $t("savePersonalData") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import api from "@/services/api";

export default {
  name: "UserPersonalData",
  components: {},
  props: {
    user: { type: Object },
    showAdditionalText: { type: Boolean },
  },
  watch: {
    // user: function () {
    //     console.log(JSON.stringify(this.user))
    //     this.userInfo = this.user
    //     this.userInfo.zipStr = this.userInfo.zip == 0 ? "" : this.userInfo.zip.toString()
    // },
  },
  computed: {
    userInfo() {
      return this.user;
    },
    additionalText() {
      return this.showAdditionalText;
    },
  },
  mounted() {
    this.getCountries();
  },
  data() {
    return {
      isFormValid: false,
      countries: [],
      // userInfo: {},
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },
  methods: {
    getCountries() {
      this.countries = [];
      api
        .post("/api/Country/GetAll", null)
        .then((response) => {
          if (response != null && response.data.value != null) {
            this.countries = response.data.value;
          }
          if (response.data.error != null) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: response.data.error,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            error.response.status == 400 &&
            error.response.data.error != null
          ) {
            this.$store.dispatch("raiseNotification", {
              show: true,
              color: "error",
              text: error.response.data.error,
            });
          }
        });
    },
    async savePersonalData() {
      this.$refs.form.validate();
      if (this.isFormValid == false) {
        console.log("Form is invalid!");
        return;
      }
      try {
        const res = await api.post("/api/User/SaveUserData", this.userInfo);
        if (res != null && res.data.error == null && res.data.value != null) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "success",
            text: this.$t("userSavedSucessfully"),
          });
          this.$store.dispatch("setPersonalData", true);
          
          window.Moengage.add_first_name(this.userInfo.firstName);
          window.Moengage.add_last_name(this.userInfo.lastName);
        }

        if (res.data.error != null) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "error",
            text: res.data.error,
          });
        }
      } catch (err) {
        console.log(err);
        if (
          (err.response.status == 400 || err.response.status == 200) &&
          err.response.data.error != null
        ) {
          this.$store.dispatch("raiseNotification", {
            show: true,
            color: "error",
            text: err.response.data.error,
          });
        }
      }
    },
  },
};
</script>
