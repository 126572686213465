const Module = () => import("./Module.vue");
import tokenService from "../auth/services/token.service";
import { i18n } from "@/plugins/i18n";

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

const routes = [
  {
    path: "/login",
    component: Module,
    beforeEnter(to, from, next) {
      if (tokenService.getUser() !== "null") next({ name: "InfoView" });
      else next();
    },
    children: [
      {
        path: "/login",
        name: "Login",
        meta: {
          title: i18n.t("signIn"),
        },
        component: () =>
          import(/* webpackChunkName: "LoginView" */ "./views/LoginView.vue"),
      },
      {
        path: "/ChangePassword",
        name: "ChangePassword",
        meta: {
          title: i18n.t("changePassword"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ChangePassword" */ "./views/ChangePassword.vue"
          ),
      },
      {
        path: "/ConfirmEmail",
        name: "ConfirmEmail",
        meta: {
          title: i18n.t("confirmEmail"),
        },
        component: () =>
          import(
            /* webpackChunkName: "ConfirmEmail" */ "./views/ConfirmEmail.vue"
          ),
      },
    ],
  },
];
export default (router) => {
  router.beforeEach((to, from, next) => {
    if(!hasQueryParams(to) && hasQueryParams(from)){
     next({name: to.name, query: from.query});
   } else {
     next()
   }
  });

  routes.forEach(function (route) {
    router.addRoute(route);
  });
};
